
body {
	color: #2c3e50;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	color: #2c3e50;
}

.cb {
	clear: both;
}

p.info {
	line-height: normal;
	display: table-cell;
	vertical-align: middle;
	height: 40px;
}
.form-control::-moz-placeholder {
	color:rgba(236, 240, 241, 0.7);
	opacity:1;
}
.form-control:-ms-input-placeholder {
	color: rgba(236, 240, 241, 0.7);
}
.form-control::-webkit-input-placeholder {
	color: rgba(236, 240, 241, 0.7);
}

.form-section .add-section option {
	color: #2c3e50;
}

/** {
	overflow-wrap: break-word;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}*/

button:focus {
	outline: 0;
}

p, a, h1, h2, h3 {
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}

.btn-del {
	display: block;
	height: 18px;
	width: 18px;
	background: url(../img/profile-sprite.png);
	background-position: -5px -314px;
}

.btn-del:hover {
	background-position: -38px -376px;
}

.navbar-default {
	background-color: #EEF1F2;
	border-bottom: 0;
	margin-bottom: 0;
	border-bottom: 3px solid #2386c8;
}

a.navbar-brand {
	height: initial;
	padding-right: 0;
}

a.navbar-brand img {
	width: 370px;
}

.navbar-default .navbar-nav>li>a {
	font-size: 18px;
	color: #333366;
	text-transform: initial !important;
	padding: 0;
	line-height: 18px;
	font-weight: bold;
}

.navbar-default .navbar-nav>li>a:hover {
	color: #ff0080
}

.header-cocialbox ul {
	display: inline-block;
	padding-left: 0;
	border-bottom: 2px solid #cccccc;
	padding: 15px 6px 0px 0px;
}

.header-cocialbox ul li {
	display: inline-block;
	margin-left: 6px;
}

.header-cocialbox ul > li > a {
	display: block;
	width: 18px;
	height: 18px;
}

a.fb {
	background: url(../img/main-sprite.png);
	background-position: 0px -31px;
}

a.tw {
	background: url(../img/main-sprite.png);
	background-position: -27px -31px;
}

a.gp {
	background: url(../img/main-sprite.png);
	background-position: -52px -31px;
}

a.email {
	background: url(../img/main-sprite.png);
	background-position: -79px -31px;
	width: 23px !important;
}

a.fb:hover {
	background-position: 0px -51px;
}

a.tw:hover {
	background-position: -27px -51px;
}

a.gp:hover {
	background-position: -52px -51px;
}

a.email:hover {
	background-position: -79px -51px;
}

/*Slider*/
.home-slider {
	padding: 0;
}

#myCarousel .nav a small {
	display: block;
}

#myCarousel .nav {
	float: right;
	position: relative;
	bottom: 10px;
}

.thumbsArea {
	margin-top: -48px;
}

.nav-thumbs li {
	margin: 0 !important;
	padding-left: 10px;
}

.nav-thumbs li a {
	background: none;
	padding: 0;
	border: 2px solid;
	border-radius: 0;
	border-color: #ffffff;
}

.nav-justified > li > a {
	border-radius: 0;
}

.slider-cap {
	text-align: left;
	bottom: 10%;
	width: 45%;
	left: 2%;

	right: auto;
	/*
	background: rgba(255,255,255,0.4);
	padding: 1% 2% 2%;
	-webkit-box-shadow: 0 0 2px 2px rgba(0,0,0,0.2);
	box-shadow: 0 0 2px 2px rgba(0,0,0,0.2);
	*/
}

.slider-cap h3 {
	font-family: garamond;
	font-size: 42px;
	line-height: 42px;
	padding: 0;
	text-shadow: 1px 1px 2px #FFFFFF;
}
/*
.slider-cap h3 span {

	text-shadow: 0 0 2px #fff;
	-webkit-text-shadow: 0 0 2px #fff;
	padding: 6px 0px;
	line-height: 54px;
	color: #000;
	text-shadow: none;
}

.slider-cap p {
	color: #333366;
	font-size: 18px;
	margin-top: 48px;
	margin-bottom: 34px;
}

.slider-cap p>span {
	background: #ffffff;
	padding: 18px;
	text-shadow: none;
}
*/


/*as per demand start*/
/*
.slider-cap {
    text-align:left;
    top: 105px;bottom:initial;
    width: 465px;
    left: 45%;
    right: auto;
    margin-left: -570px;
    padding: 0;
}
.slider-cap h3 {
    font-family:garamond;
    font-size: 42px;
    line-height: 42px;
}
*/
.slider-cap h3 span {
	background-color: #2386c8;
	padding: 6px 0px;
	line-height: 64px;

	color: #ffffff;
	text-shadow: none;
}
.slider-cap p {
	color: #333366;
	font-size: 18px;
	margin-top:0;
	margin-bottom: 0;
}
.slider-cap p>span{
	background: #ffffff;
	padding: 18px;
	text-shadow: none;
}

/*as per demand end*/





.slider-cap a {
	text-shadow: none;
	text-decoration: none;
	background-color: #2C3E50;
	color: #ffffff;
	font-size: 18px;
	padding: 15px 20px;
}

.carousel-inner {
	background-color: #D1CDB4;
}

.carousel-inner .item img {
	margin: 0 auto;
	width: 100%;
	height: auto;
	max-width: none;
}

/*Search Area */
.searchArea {
	background-color: #2C3E50;
}

.searchArea ul {
	margin: 0;
	padding: 13px 0;
	text-align: center;
	display: table;
	width: 100%;
}

.searchArea li:first-child {
	width: 30%;
}

.searchArea li:nth-child(2) {
	width: 50%;
}

.searchArea li:nth-child(3) {
	width: 20%;
}

.searchArea li span {
	font-size: 18px;
	color: #ffffff;
	width: 100%;
	display: block;
	text-align: left;
	padding: 0 10px 0 0;
}

.nav-pills > li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {
	color: #fff;
	background-color: #428bca;
	border: 2px solid #ff0080;
}

.searchArea ul li {
	display: table-cell;
	vertical-align: middle;
	margin-right: 10px;
}

.searchArea ul li input[type="text"] {
	background: transparent;
	box-shadow: none;
	border: 1px solid #999999;
	padding: 9px;
	width: 100%;
	color: #ffffff;
	font-size: 20px;
}

.searchArea ul li input[type="button"] {
	/*background: #18BC9C;  */
	background: transparent;
	border: 1px solid #18BC9C;
	color: #ECF0F1;
	font-weight: bold;
	padding: 6px 0;
	font-size: 24px;
	text-shadow: none;
	width: 100%;
	height: 48px;
}

.searchArea ul li input[type="button"]:before, .searchArea ul li input[type="button"]:hover {
	/*background: #ff0080;*/
	border-color: #ff0080;
}

.searchArea ul li input[type="text"]:focus, .searchArea ul li input[type="button"]:focus {
	outline: 0;
}

h2.content-heading {
	font-size: 24px;
	border-bottom: 3px solid #2386c8;
	padding-bottom: 6px;
	margin-bottom: 30px;
	margin-top: 60px;
	font-weight: normal;
}

.profileprojects {
	/*padding-right: 0;*/
}

.profileprojects h2.content-heading a {
	float: right;
	font-size: 14px;
	margin-left: 20px;
	color: #18bc9c;
	line-height: 30px;
}

.profile-about {
	padding-left: 0;
	padding-right: 0;
}

.helps-disc {
	background-color: #ECF0F1;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: -10px;
}

.helps-disc >p {
	padding: 15px 90px 20px 20px;
	text-align: left;
	min-height: 90px;
	line-height: 24px;
	word-wrap: break-word;
}

.helps-disc > p > a {
	color: #2c3e50;
}

div.helps-likes {
	background-color: #2C3E50;
	position: relative;
	right: 1px;
	width: 55px;
	height: 55px;
	float: right;
	margin: 20px 20px 15px 10px;
	color: #ffffff;
	text-align: center;
	line-height: 18px;
	padding: 4px;
	cursor: default;
}

.glyphicon:hover {
	color: #ff0080;
}

.helps-likes .glyphicon {
	/*font-size:26px;*/
}

.header-cocialbox {
	width: 400px;
	float: right;
	height: 56px;
	text-align: right;
}

.navbar .navbar-collapse {
	float: right;
	width: 730px;
	margin-left: 20px;
	margin-top: 66px;
}

.navbar .navbar-collapse .nav-left {
}

.navbar .navbar-collapse .nav-right {
	padding-right: 15px;
}

.navbar .navbar-collapse .nav-right li {
	margin-left: 16px;
}

.navbar-default .navbar-brand {
	padding-top: 20px;
	padding-bottom: 20px;
}

.navbar .navbar-collapse .nav-left > li {
	margin-right: 25px;
}

.navbar-default .navbar-nav>li>a.header-search-button {
	background: url(../img/main-sprite.png);
	width: 24px;
	height: 24px;
	margin-top: -3px;
}

.navbar-default .navbar-nav>li>a.header-search-button:hover {
	background: url(../img/main-sprite.png);
	background-position: -26px 0px;
}

.box-type-1 {
	padding-left: 0;
	padding-right: 10px;
	margin-bottom: 10px;
}

.type-1 {
	padding-left: 15px;
	padding-right: 10px;
	margin-bottom: 10px;
	position: relative;
}

.box-type-1 > a {
	display: block;
	height: 245px;
	overflow: hidden;
	background: #D8DEE1;
}

.box-type-1 img {
	width: 100%;
}

.box-type-1 img.landscape {
	height: 100%;
	width: auto;
	min-width: 100%;
}

.box-type-2 {
	margin-bottom: 30px;
}

.type-2 {
	margin-bottom: 30px;
}

.box-type-2 > a {
	height: 190px;
	overflow: hidden;
	display: block;
}

.box-type-2 img {
	height: auto;
	width: 100%;
	min-width: 100%;
}

.box-type-2 img.landscape {
	height: auto;
	width: auto;
	max-width: 100%;
}

.box-2-disc {
	color: #666666;
	overflow-wrap: break-word;
}

.box-2-disc a {
	text-transform: uppercase;
	text-decoration: none;
	font-weight: bold;
	color: #2386c8;
}

.box-2-disc a:hover {
	color: #ff0080;
}

.box-2-disc p {
	margin: 0 0 5px;
	height: 40px;
}

h2.box-2-title {
	font-size: 24px;
	font-weight: bold;
	color: #333366;
	margin-top: 5px;
	margin-bottom: 3px;
	overflow: hidden;
	height: 52px;
	line-height: 26px;
}

h2.box-2-title > a {
	color: #2c3e50;
	text-transform: inherit;
}

.box-2-disc .status-bar {
}

#progressbar {
	width: 100%;
	height: 20px;
	background-color: #ECF0F1;
	margin: 8px 0;
	clear: both;
}

#progress {
	background: #2386C8;
	height: 20px;
	max-width: 100%;
	float: left;
	-webkit-animation: progress 2s 1 forwards;
	-moz-animation: progress 2s 1 forwards;
	-ms-animation: progress 2s 1 forwards;
	animation: progress 2s 1 forwards;
}

.karamaShop {
	background: #ECF0F1;
}

.karmaShop-inner {
	margin-bottom: 30px;
}

.karmaShop-inner ul {
	list-style: none;
	padding-left: 0;
}

.box-type-3 {
	margin-bottom: 30px;
	overflow: hidden;
	min-height: 185px;
}

.box-type-3 img {
	float: left;
	width: 48% !important;
	height: 185px;
}

.karma-right {
	width: 50%;
	float: right;
	padding-left: 1%;
}

.author-detail {
}

.author-detail .helps-likes {
	margin: 0;
	padding: 8px;
}

.author-detail .helps-likes span {
	margin-left: -8px;
}

.helps-likes small {
	color: #ffffff;
}

.helps-likes span {
	font-size: 20px;
	font-weight: bold;
	width: 55px;
	margin-left: -4px;
	display: block;
	margin-bottom: -13px;
	height: 18px;
	overflow: hidden;
}

.post-date p {
	margin: 12px 0px 0px 0px;
	background: #D8DEE1;
	padding: 3px;
	color: #666666;
}

.author-name {
	float: left;
	width: 73%;
	overflow-wrap: break-word;
}

.author-detail > div > p {
	color: #666666;
}

.author-detail a {
	text-transform: uppercase;
	font-weight: bold;
	color: #2386c8;
}

.author-detail a:hover {
	color: #ff0080;
	text-decoration: none;
}

h2.shop-box-title {
	font-size: 24px;
	font-weight: bold;
	border-bottom: 3px solid #00cc99;
	margin-top: 0;
	padding-bottom: 0px;
	height: 59px;
	margin-top: 7px;
	overflow: hidden;
}

h2.how-it-works {
	font-size: 17px;
	font-weight: bold;
	padding-bottom: 0px;
	height: 25px;
	margin-top: 25px;
	overflow: hidden;
}

h2.shop-box-title > a {
	color: #2c3e50;
}

h2.how-it-works > a {
	color: #2c3e50;
}

.full-vid {
	margin-top: 60px;
	border-top: 3px solid #2386c8;
	padding-top: 28px;
}

.full-vid h3 {
	margin-top: 0;
	text-align: center;
	color: #2c3e50;
	margin-bottom: 35px;
	font-weight: normal;
}

.responsive-video {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 60px;
	overflow: hidden;
}

.responsive-video iframe, .responsive-video object, .responsive-video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.testimonial {
}

.testi-slider {
	background: #ffffff;
}

.testi-slider .item {
	height: 140px;
}

.testi-content {
	width: 100%;
	padding: 0 30px;
}

.testi-content .pro-img-holder {
	float: left;
	width: 10%;
	border: 3px solid #00cc99;
	/* padding-right: 20px; */
	max-height: 181px;
	overflow: hidden;
}

.testi-content .pro-img-holder img {
	width: 100%;
	height: auto;
}

.item .testi-content img {
	float: left;
	margin-right: 22px;
	width: 100%;
	height: auto;
}

.testi-content .text-holder {
	width: 86%;
	margin: 0 0 2% 12px;
	float: left;
	border-bottom: 3px solid #ECF0F1;
}

.testi-right {
	width: 100%;
	padding: 0;
}

.testi-right>span {
	position: relative;
	bottom: -11px;
	background: #ffffff;
	float: right;
	color: #ff0099;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: bold;
	padding: 0 10px;
}

.testi-right>span>small {
	text-transform: capitalize;
	color: #2c3e50;
	font-weight: normal;
	display:block;
}

.testi-right>span>p {
}

.testi-right p {
	font-size: 18px;
	font-style: italic;
	line-height: 27px;
}

.carousel-control {
	background: none !important;
}

.carousel-control {
	width: 20px !important;
	top: 50%;
	height: 40px;
	text-align: center;
}

.testi-control-left {
	background: url(../img/main-sprite.png);
	background-position: -36px -271px;
	width: 20px;
	height: 31px;
	display: inline-block;
	vertical-align: middle;
}

.testi-control-right {
	background: url(../img/main-sprite.png);
	background-position: -62px -271px;
	width: 20px;
	height: 31px;
	display: inline-block;
	vertical-align: middle;
}

/*--------------------[Search Single post Starts]---------*/
.mt20 {
	margin-top: 20px;
}

.search-single-post {
	margin-bottom: 30px;
	overflow: hidden;
	background: #eef1f2;
	border: 1px solid #b1b6b9;
	min-height: 160px;
}

.search-single-post h3 {
	font-size: 24px;
	color: #2c3e50;
	font-weight: bold;
	text-transform: initial !important;
	border-bottom: 2px solid #2386c8;
	line-height: 37px;
	margin: 0 0 10px;
}

.search-single-post .search-post-image {
	width: 34%;
	position: relative;
	overflow: hidden;
	max-height: 160px;
}

.search-single-post .search-post-details {
	width: 66%;
	padding: 5px 10px;
}

.search-single-post .search-post-details span {
	margin-bottom: 12px;
	display: block;
	color: #2c3e50;
	font-size: 14px;
	font-weight: bold;
}

/*----------------------------[Search Single post Ends]---------*/

footer {
	background: #2C3E50;
	padding-bottom: 10px;
}

.footer-social {
	clear:both;
	overflow:hidden;
	padding-top: 20px;
}

.footer-social ul {
	list-style: none;
	vertical-align: middle;
	text-align: center;
	padding: 20px;
	margin-bottom: 0;
	border-top: 1px solid #ABB2B9;
}

.footer-social ul li {
	display: inline-block;
	margin: 0px 15px;
}

.footer-social li a {
	color: #ffffff;
	line-height: 20px;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: normal;
}

.footer-social li span {
	display: block;
	width: 18px;
	height: 18px;
	background-image: url(../img/main-sprite.png);
	float: left;
	margin-right: 10px;
}

span.f-fb {
	background-position: -4px -313px;
}

span.f-tw {
	background-position: -39px -313px;
}

span.f-ins {
	background-position: -74px -313px;
}
span.f-linkedin {
	background-position: -110px -312px;
}
.footer-bottom {
	margin-top: 55px;
}

.foot-links .col-md-4 {
	padding-right: 0;
}

.foot-links p {
	color: #ffffff;
	font-size: 18px;
	font-weight: normal;
	padding-left: 14px;
	text-transform: uppercase;
	margin-top: 50px;
}

.foot-links p a {
	font-size: 18px;
}

.foot-links ul {
	padding-left: 14px;
	margin-bottom: 0;
}

.foot-links ul li {
	padding-bottom: 11px;
}

.foot-links a {
	color: #cccccc;
	font-weight: normal;
	font-size: 15px;
}

.foot-links a:hover {
	text-decoration: none;
	color: #ffffff;
}

.footer-bottom h4 {
	color: #ffffff;
	font-size: 14px;
	margin-top: 0;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 30px;
}

.footer-right {
}

.footer-right ul {
	padding-left: 14px;
	margin-bottom: 0;
}

.footer-right ul li {
	display: inline-block;
	margin-right: 16px;
	width: 15%;
}

.footer-right p {
	color: #ffffff;
	font-size: 18px;
}

.footer-right li a {
}

.footer-right li a img {
}

.subcription {
	padding-left: 14px;
	margin-top: 40px;
}

.subcription input[type="text"] {
	background-color: transparent;
	border: 1px solid #ABB2B9;
	height: 50px;
	vertical-align: middle;
	padding-left: 20px;
	color: #ffffff;
	font-size: 18px;
	font-weight: normal;
	width: 75%;
	display: block;
	float: left;
	line-height: 20px;
}

.subcription .hvr-sweep-to-left {
	width: 25%;
}

.subcription input[type="button"] {
	vertical-align: middle;
	height: 50px;
	font-size: 18px;
	font-weight: bold;
	color: #ffffff;
	background: Transparent;
	border: 1px solid #18BC9C;
	padding: 8px 10px;
	display: block;
	float: left;
	width: 100%;
}

p.footer-cont {
	margin-top: 32px;
}

.footer-cont a {
	color: #00cc99;
}

.login-form {
	background: #EEF1F2;
	min-height: 300px;
	margin-bottom: 60px;
	margin-top: 60px;
	padding: 30px;
}

.login-form h2.content-heading, .signup-form h2.content-heading {
	margin: 0;
	margin-bottom: 30px;
}

.form-section {
	clear: both;
	margin-bottom: 20px;
	min-height: 40px;
}

.form-section:after {
	content: '';
	display: block;
	clear: both;
}

.form-section .bottomline {
	clear: both;
	margin-left: 135px;
}

.form-section .form-section-left {
	width: 30%;
	float: left;
}

.form-section .form-section-right {
	width: 70%;
	float: left;
	overflow: hidden;
}

.signup-form .form-section .add-sec-bottom .form-section-right {
	width: 100% !important;
	padding-right: 0 !important;
}

.selectinside {
	/*border-right: 1px solid #D8DEE1;*/
}

.form-section .form-section-right-1 {
	width: 330px;
}

.user-img img {
	float: left;
	width: 100px;
	height: 100px;
}

.form-section .form-section-right-1 .chkbxtitle {
	width: 290px;
	line-height: 18px;
}

.opt-btns {
	float: left;
	margin-left: 10px;
	height: 40px;
	min-width: 100px;
}

.form-section-third .opt-btns {
	margin-left: 0;
}

.opt-btns a {
	display: block;
	color: #18BB9C;
	line-height: 20px;
}

.form-section-third {
	float: left;
	line-height: 40px;
	width: 170px;
}

.form-section .form-section-right.leftmrl {
	margin-left: 30%;
	overflow: visible;
}

.form-horizontal .control-label {
	text-align: left;
	font-weight: normal;
	padding: 0;
	line-height: 42px;
}

.interstselectlist {
	width: 70%;
	float: left;
}

.interstselectlist .checkbox {
	width: 50%;
	float: left;
	margin-bottom: 10px;
}

.form-horizontal .h26 .control-label {
	line-height: 26px;
}

.signup-form {
	background: #EEF1F2;
	min-height: 300px;
	margin-bottom: 60px;
	margin-top: 60px;
	padding: 30px;
}

.login-fb, .signup-fb {
	/*width: 457px;*/
	border-top: 1px solid #999999;
	/*margin-left: -30px;*/
	padding-top: 30px;
	text-align: center;
}

.signup-fb {
	/*width: 652px;*/
}

.form-section input, select {
	height: 40px;/*-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;*/
}

.form-section input[type="text"], .form-section input[type="email"], .form-section input[type="password"], .form-section input[type="date"], select {
	border: 1px solid #D8DEE1;
	width: 100%;
	padding: 5px 10px;
}

.form-section input[type="checkbox"] {
	height: inherit;
}

.form-section input[type="submit"] {
}

.signin-btn {
	background: #18BC9C;
	border: 1px solid #18BC9C;
	color: #ffffff;
	height: 40px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	min-width: 108px;
	font-size: 18px;
	font-weight: bold;
}

.checkbox .signin-btn {/*float:right;*/
	margin-top: 10px;
}

.signin-btn:hover {
	background: #ff0080;
	border-color: #ff0080;
}

p.green-msg, a.green-msg, p.green-msg , span.green-msg {
	color: #00cc99;
}

a.blue-msg {
	color: #2386c8;
}

p.error, p.error a {
	color: #ff0080;
	line-height: 17px;
	margin-top: 10px;
}

.error {
	color: #ff0080;
	line-height: 17px;
}

.form-section input[type="checkbox"].realchkbx {
	visibility: hidden;
	margin: 0;
	padding: 0;
}

.visvchkbx {
	width: 24px;
	height: 24px;
	background: url(../img/input-sprite.png);
	background-position: -49px -49px;
	display: block;
	float: left;
	cursor: pointer;
}

input[type="checkbox"].realchkbx:checked + .visvchkbx {
	background-position: -9px -49px;
}

.radio label, .checkbox label {
	padding: 0;
}

.chkbxtitle {
	display: block;
	line-height: 24px;
	height: 24px;
	float: left;
	margin-left: 10px;
}

.interstselectlist .child-box .chkbxtitle {
	width: 180px;
	line-height: 20px;
	height: auto;
}

.form-section input[type="radio"].realradio {
	visibility: hidden;
	margin: 0;
	padding: 0;
}

.visvradio {
	width: 26px;
	height: 26px;
	background: url(../img/input-sprite.png);
	background-position: -49px -8px;
	display: block;
	float: left;
}

input[type="radio"].realradio:checked + .visvradio {
	background-position: -9px -8px;
}

.radiotitle {
	display: block;
	line-height: 24px;
	height: 24px;
	float: left;
	margin-left: 10px;
}

.mt8 {
	margin-top: 8px;
}

.mb25 {
	margin-bottom: 25px;
}

.mb60 {
	margin-bottom: 60px;
}

.mr20 {
	margin-right: 20px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
	padding-top: 0;
}

.settings {
}

.settings h2.content-heading {
	margin-bottom: 24px;
}

ul.ac-settings {
	list-style: none;
	padding-left: 0;
}

ul.ac-settings li {
}

ul.ac-settings li a {
	color: #2c3e50;
	text-decoration: none;
	padding: 6px 0;
	display: block;
	font-size: 18px;
	font-weight: normal;
}

ul.ac-settings li.active a {
	font-weight: bold;
	background: url(../img/active.png);
	background-repeat: no-repeat;
	background-position: center right;
}

ul.ac-settings li a:hover {
}

.noti-stng input[type="checkbox"].realchkbx {
	visibility: hidden;
	margin: 0;
	padding: 0;
}

.settings .noti-stng .mt8 {
	margin: 0;
	line-height: 10px;
}

.noti-row-single label {
	font-weight: normal;
}

.noti-stng {
}

.noti-row {
	min-height: 45px;
	border-bottom: 1px solid #cccccc;
	padding: 11px 0 6px 10px;
}

.noti-row:after {
	content: "";
	display: block;
	clear: both;
}

.privacysettings .noti-row:first-child {
	border-top: 1px solid #cccccc;
}

.noti-kind {
	width: 50%;
	float: left;
	padding-right: 10px;
}

.noti-center {
	width: 25%;
	float: left;
	text-align: center;
}

.noti-email {
	width: 25%;
	float: left;
}

.noti-lower-btns {
	margin-top: 20px;
}

.noti-lower-btns .btns-left {
	float: left;
}

.noti-lower-btns .btn-right {
	float: right;
}

.btn-right button {
	background: #DADFE2;
	border-color: #DADFE2;
}

button.btn-gray {
	border: 1px solid #dddddd;
	background: #dadfe1;
	border: 1px solid #dadfe1;
	height: 40px;
	padding: 6px 12px;
	font-size: 18px;
	font-weight: bold;
	margin-left: 11px;
	min-width: 108px;
}

button.btn-gray:hover {
	background: #2c3e50;
	color: #fff;
	border: 1px solid #2c3e50;
}

.noti-lower-btns button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.form-section .add-section {
	width: 70% !important;
	float: left;
}

.add-sec-bottom {
	width: 33.3% !important;
	padding: 8px 8px 8px 0px;
	float: left;
	overflow-x: hidden;
}

.add-sec-bottom input[type="text"] {
	width: 100% !important;
}

.add-sec-bottom select {
	padding-right: 170px;
	padding-left: 8px; /*color: #9D9B9B;*/
}

.add-sec-bottom:last-child {
	margin-right: 0;
}

.add-sec-bottom select {
	background-position: 108px -77px;
}

.impred {
	color: #ff0080;
}

.h26 {
	height: 26px;
	min-height: 26px;
}

select {
	background: #fff url(../img/input-sprite.png) no-repeat;
	border-radius: 0;
	background-position: 120% -77px;
	/*width: 290px;*/
	padding: 0 55px 0px 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	z-index: 1;
}

textarea {
	border: 1px solid #D8DEE1;
	width: 100%;
	max-width: 100%;
	min-height: 70px;
	padding: 10px;
}

.lh24 {
	line-height: 24px !important;
}

.btns-left.leftmrl {
	margin-left: 30%;
}

.account-preferences h4 {
	margin: 0;
	line-height: 40px;
	font-weight: bold;
}

.account-preferences {
	margin-bottom: 30px;
}

.btspacer3 {
	border-top: 3px solid #dadfe1;
	margin-bottom: 22px;
}

.redbtn {
	background: #ff0080;
	border: 1px solid #ff0080;
}

.redbtn:hover {
	background: #2386c8;
	border: 1px solid #2386c8;
}

/* Add an Offer  */
.offer-img img {
	float: left;
	width: 262px;
	height: 140px;
}

.form-section .long-section {
	margin-bottom: 0;
	/*	width: 445px;*/
	float: left;
}

.form-section .set-marg {
	margin-bottom: 0;
}

.form-section .set-marg .add-sec-bottom {
	margin-top: 0;
	margin-bottom: 0;
}

.last-checks {
	margin-bottom: 0;
}

.last-checks .form-section-right {
	margin-bottom: 10px;
}

.edition-section {
	margin-top: -30px;
	height: 60px;
	border-bottom: 1px solid #ccc;
	margin-bottom: 30px;
}

.edition-section .edit-right {
	float: right;
	margin-top: 20px;
	margin-bottom: 20px;
}

.edition-section .edit-right a {
	display: block;
	float: left;
	padding: 0 10px;
	font-weight: bold;
	color: #2c3e50;
	text-decoration: underline;
}

.edit-right a.danger-color {
	color: #ff0080;
}

span.edit-page-title {
}

.user-popularity .no-of-follows .prof-pblc {
	margin: 0 auto;
	float: none;
}

/*  Profile 01   */
.user-credentials {
	padding: 0;/*width: 699px;*/
}

.user-credentials .prof-user {
	margin: 25px 0 20px 0;
	overflow: hidden;
}

.user-credentials .user-info {
	width: 65%;
	padding-right: 15px;
	float: left;
}

.user-credentials .user-info>a {
	float: left;
	margin: 7px 25px 5px 0px;
	color: #3399cc;
	font-weight: bold;
}

.user-credentials .prof-user h2 {
	font-size: 24px;
	font-weight: bold;
	margin-top: 0;
	text-transform: uppercase;
}

.user-credentials .user-info .user-age, .user-info .user-address {
	font-size: 14px;
	font-weight: bold;
}

.user-info .about-user {
	overflow-wrap: break-word;
	overflow: hidden;
}

.user-info .about-user p {
	width: 330px;
	float: left;
	margin-top: 15px;
}

.user-info .about-user a {
	margin: 0px 5px -27px 7px;
}

.user-info .about-user a:hover {
}

.user-awards {
	background: #F6F8F8;
	padding-bottom: 10px;
	padding-top: 10px;
	/*width: 226px;*/
	/*margin-left: -15px;*/
	border-left: 1px solid #D4D6D8;
	height: 300px;
}

.user-awards ul.user-badges {
	list-style: none;
	padding-left: 0;
	margin-bottom: 5px;
}

.user-awards ul.user-badges li {
	display: block;
	height: 36px;
	padding-left: 30px;
	background: url(../img/profile-sprite.png);
	background-repeat: no-repeat;
	line-height: 26px;
}

ul.user-badges li.equ {
}

ul.user-badges li.volu {
	background-position: 0px -38px;
}

ul.user-badges li.dona {
	background-position: 0px -75px;
}

ul.user-badges li.Kpoint {
	background-position: 0px -112px;
}

ul.user-badges li.contri {
	background-position: 0px -145px;
}

.user-awards>p {
	margin-bottom: 5px;
}

.user-popularity {
	/*width: 260px;*/
	padding: 10px 0;
	border: 1px solid #A9AEB1;
	border-top: 0;
	border-bottom: 0;
	float: right;
	height: 300px;
}

ul.followed-by {
	list-style: none;
	padding-left: 0;
}

ul.followed-by li {
	display: inline-block;
}

ul.followed-by li a {
}

ul.followed-by li a img {
	width: 45px;
	height: 45px;
}

span.all-follows {
	float: right;
}

span.all-follows a {
	text-decoration: none;
	color: #00cc99;
}

span.all-follows a:hover {
	text-decoration: underline;
}

.user-popularity .popular-projects {
	padding: 15px 20px;
	border-top: 0;
	border-bottom: 1px solid #A9AEB1;
}

.popular-projects .PP-head {
	float: left;
	display: block;
	width: 33%;
	font-weight: bold;
	padding: 20px 0;
	text-align: center;
}

.popular-projects .PP-head p {
}

.popular-projects .PP-owned {
	float: left;
	width: 33%;
	text-align: center;
}

.popular-projects .PP-owned p {
}

.popular-projects .PP-owned p span {
	font-size: 24px;
	display: block;
}

.no-of-follows {
	padding: 7px 0;
	border-bottom: 1px solid #A9AEB1;
}

.no-of-follows .PP-head {
	float: left;
	display: block;
	height: 60px;
	font-weight: bold;
	padding: 20px 0;
}

.no-of-follows .PP-head p {
}

.no-of-follows .PP-owned {
	float: left;
	width: 50%;
	text-align: center;
}

.no-of-follows .PP-owned p {
	font-weight: bold;
}

.no-of-follows .PP-owned p a {
	color: #2c3e50;
}

.no-of-follows .PP-owned p span {
	font-size: 24px;
	display: block;
	font-weight: normal;
}

.more-info-user {
	padding: 20px 20px 10px 20px;
}

.more-info-user p {
	margin-bottom: 0;
}

.more-info-user p a {
	color: #00cc99;
}

.more-info-user>a {
	display: block;
	margin-top: 10px;
	text-decoration: none;
	color: #00cc99;
	float: left;
	margin-right: 20px;
}

.user-tabs-full {
	background-color: #F6F8F8;
	border: 1px solid #989EA1;
	border-right: 0;
	border-left: 0;
}

ul.user-tabs {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

ul.user-tabs li {
	float: left;
}

ul.user-tabs li a {
	text-transform: uppercase;
	padding: 13px 35px;
	display: block;
	text-decoration: none;
	font-size: 18px;
	color: #2c3e50;
}

ul.user-tabs li.active a {
	background: #2C3E50;
	color: #ffffff;
}

.content-left {
	padding: 60px 0;
}

.user-cont-right {
	padding: 0;
}

.user-cont-right a.load-more {
	display: block;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	background: #666666;
	color: #ffffff;
	text-decoration: none;
	padding: 6px;
	height: 36px;
	width: 85%;
	float: left;
	margin-left: 12%;
}
.say-some {
	min-height: 124px;/* margin-bottom: 20px; */
}

.say-some:after {
	content: "";
	display: block;
	clear: both;
}

.say-area {
	float: left;
	width: 90%;
	border: 1px solid #D4D6D8;
	padding: 9px;
	position: relative;
	margin-bottom: 20px;
}

.say-area .media-list {
	width: 100%;
	float: left;
}

.say-area .media-list .progress {
	height: 6px;
	overflow: hidden;
	background-color: #f5f5f5;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
	width: 50px;
	border-radius: 0;
	float: left;
}

.say-area .media-list .media-list-box {
	width: 50px;
	height: 50px;
	float: left;
	position: relative;
	margin-right: 20px;
	margin-top: 10px;
	margin-bottom: 15px;
}

.say-area .media-list .media-list-box img {
}

.say-area .media-list .media-list-box a {
	position: absolute;
	top: -7px;
	right: -8px;
	font-size: 11px;
	line-height: 13px;
	width: 14px;
	height: 14px;
	text-align: center;
	background: #00cc99;
	color: #fff;
	border-radius: 100%;
}

.say-area textarea {
	border: 0;
	border-bottom: 1px solid #A9AEB1;
	resize: none;
}

.say-area textarea:focus {
	outline: 0;
}

.say-area input[type="file"] {
	width: 1px;
	height: 1px;
	position: absolute;
	top: 0px;
}

.say-area a {
}

a.edit-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url(../img/profile-sprite.png);
	background-position: -2px -183px;
}

a.edit-icon:hover {
	opacity: .6
}

.say-area a.say-link {
	display: block;
	width: 28px;
	height: 28px;
	float: left;
	background: url(../img/profile-sprite.png);
	margin-left: 5px;
	background-position: 0px -206px;
}

.say-area a.say-photo {
	display: block;
	width: 28px;
	height: 28px;
	float: left;
	background: url(../img/profile-sprite.png);
	margin-left: 5px;
	background-position: 0px -247px;
}

.say-area a.say-vid {
	display: block;
	width: 28px;
	height: 28px;
	float: left;
	background: url(../img/profile-sprite.png);
	margin-left: 5px;
	background-position: 0px -279px;
}

.say-attach {
	float: right;
}

.user-comment {
	margin-bottom: 20px;
	overflow: hidden;
}

.user-comment .usr-img-container {
	float: left;
	margin-right: 20px;
}

.user-comment>img {
}

.user-comm-content {
	float: left;
	width: 100%;
	border: 1px solid #A9AEB1;
	padding: 19px;
}

.user-comm-content p {
	margin-bottom: 20px;
	text-align: justify;
}

.user-comm-content img {
	float: left;
	margin-right: 1px;
}

.comm-lower-btns {
	height: 40px;
	border-top: 1px solid #A9AEB1;
	margin-top: 16px;
	margin-bottom: -19px;
	padding: 5px 0;
	width: 100%;
	margin-left: 0;
}

.comm-lower-btns .left-btns {
	float: left;
	padding: 0 10px;
}

.comm-lower-btns .left-btns span {
	min-width: 40px;
	float: left;
	font-size: 16px;
	line-height: 27px;
	margin-right: 10px;
	color: #999999;
}

.comm-lower-btns .right-btns {
	float: right;
	padding: 4px 0px;
}

.comm-lower-btns .left-btns a {
	display: block;
	height: 25px;
	width: 25px;
	float: left;
	margin-right: 12px;
	background: url(../img/profile-sprite.png);
}

.comm-lower-btns .left-btns a.heart {
	background-position: -3px -369px;
}

.comm-lower-btns .left-btns a.heart:hover {
	background-position: -4px -397px;
}

.comm-lower-btns .left-btns a.heart.liked {
	background-position: -4px -397px;
}

.comm-lower-btns .left-btns a.balon {
	background-position: -3px -338px;
}

.comm-lower-btns .right-btns a.del {
	display: block;
	width: 20px;
	height: 20px;
	background: url(../img/profile-sprite.png) -6px -314px;
	float: right;
	margin-left: 5px;
}

.comm-lower-btns .right-btns a.del:hover {
	background-position: -39px -376px;
}

.comm-lower-btns .right-btns p {
	margin-bottom: 0;
	float: right;
	color: #666666;
	width: 250px;
	max-height: 22px;
	overflow: hidden;
	text-align: right;
}

/*new buttons for home page*/



.user-comment .a-commented {
	overflow: hidden;/* margin-left: 74px; */
}

.a-commented {
	float: left;
	width: 100%;
	padding: 19px;
	margin-left: 0;
	border: 1px solid #A9AEB1;
	border-top: 0;
	background: #EEF1F2;
}

.comment-from {
	overflow-wrap: break-word;
}

.comment-from p {
	margin-left: 70px;
	padding-right: 55px;
	text-align: justify;
}

.add-a-comment {
}

#user_post_btn, #project_post_btn {
	position: relative;
	border: 1px solid #00AC8F;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #18BC9C;
	color: #ffffff;
	padding: 5px 28px;
	font-weight: bold;
	font-size: 18px;
	height: 38px;
}

.comment-fixed-height {
	min-height: 55px;
	position: relative;
	margin-bottom: 5px;
}

.comment-fixed-height .delete-comment {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	background: url(../img/profile-sprite.png);
	right: 0;
	top: 0;
	background-position: -26px -425px;
}

.comment-fixed-height .delete-comment:hover {
	background-position: -38px -375px;
}

.comment-fixed-height .edit-comment {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	background: url(../img/profile-sprite.png);
	right: 25px;
	top: 0;
	background-position: -2px -180px;
}

.comment-fixed-height .edit-comment:hover {
	opacity: .6;
}

.add-a-comment input[type="text"] {
	width: 100%;
	height: 44px;
	border: 1px solid #A9AEB1;
	padding-right: 124px;
	padding-left: 10px;
}

.add-a-comment input[type="text"]:focus {
	outline-color: #00AC8F
}

.add-a-comment button {
	position: relative;
	float: right;
	top: -41px;
	right: 3px;
	border: 1px solid #00AC8F;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #18BC9C;
	color: #ffffff;
	padding: 5px 28px;
	font-weight: bold;
	font-size: 18px;
	height: 38px;
	margin-bottom: -41px;
}

.user-cont-right h2.content-heading {
	margin-top: 0;
}

.offer-right-col .karma-right {
	width: 100%;
	padding-left: 0;
	margin-bottom: 30px;
}

.offer-D-right .offer-right-col .karma-right > a {
	display: block;
	height: 167px;
	overflow: hidden;
	width: 100%;
}

.offer-D-right .offer-right-col .karma-right h2.shop-box-title {
	font-size: 18px;
	width: 100%;
	padding-left: 10px;
}

.offer-D-right .offer-right-col .karma-right>img {
	margin-bottom: 0;
}

.offer-D-right .offer-right-col .karma-right .S-needs-right-below {
	padding: 10px;
	border: 1px solid #A9AEB1;
}

.offer-right-col .karma-right>img {
	max-width: 100%;
	margin-bottom: 10px;
}

.aboutleft {
	border: 1px solid #a9aeb1;
	margin-top: 40px;
	margin-bottom: 40px;
	padding: 25px 20px 0px 20px;
	overflow-wrap: break-word;
}

.aboutleft .box003 a.edit-icon {
	position: relative;
	top: 5px;
	left: 10px;
}

.aboutleft h3 {
	margin-top: 0;
	margin-bottom: 15px;
	font-size: 18px;
	text-transform: uppercase;
}

.aboutleft a.edit {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 0px 5px -7px 7px;
	background: url(../img/profile-sprite.png);
	background-position: -2px -183px;
}

.aboutleft p {
	margin-bottom: 45px;
}

.aboutright {
	border: 1px solid #a9aeb1;
	margin-top: 60px;
	margin-bottom: 60px;
	padding: 25px 20px 0px 20px;
}

.aboutright a.edit-icon {
	float: right;
}

.rightsmb {
	margin-bottom: 30px;
}

.rightsmb span {
	font-size: 16px;
	color: #666666;
}

.rightsmb p {
	font-size: 16px;
	margin-left: 20px;
}

/*  messages  */
.messages-page {
	padding: 30px 0;
}

.msg-h-full {
	background: #F6F8F8;
	border-bottom: 1px solid #989EA1;
}

.msg-header {
	padding: 16px 0;
	height: 78px;
}

.msg-header-left {
	float: left;
	padding: 5px 10px;
	width: 300px;
}

.msg-header-left .btn-group.open .dropdown-toggle {
	-webkit-box-shadow: none;
	-moz-webkit-box-shadow: none;
	box-shadow: none;
}

.msg-header-left a {
	font-size: 24px;
	font-weight: normal;
	color: #2C3E50;
}

.msg-header-left>a.back-to-gallary {
	font-size: 18px;
	padding: 6px 0;
	display: block;
	min-width: 185px;
}

.msg-header-left>a.back-to-gallary span.left-darkArrow {
	display: block;
	height: 24px;
	width: 24px;
	background: url(../img/profile-sprite.png);
	float: left;
	background-position: -39px -454px;
}

.msg-header-right {
	float: right;
	position: relative;
	margin-right: 15px;
}

.msg-header-right input[type="text"] {
	width: 457px;
	height: 46px;
	padding: 4px 16px;
	border: 1px solid #a9aeb1;
}

.msg-header-right button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #00AC8F;
	background: #18BC9C;
	color: #ffffff;
	position: absolute;
	right: 3px;
	top: 3px;
	font-size: 18px;
	height: 40px;
	font-weight: bold;
	padding: 4px 15px;
	margin-left: -100px;
}

.msg-header-left .btn-group button {
	background: none;
	border: none;
	font-size: 24px;
}

.mail-list-left {
	width: 358px;
	padding: 0;
	background: #EEF1F2;
	/*height: 527px;*/
	min-height: 527px !important;
}

.mail-list-left .mail-nav {
	padding: 20px;
	background: #EEF1F2;
}

.mail-list-left .mail-nav a {
	font-size: 18px;
	font-weight: normal;
	color: #2C3E50;
	text-decoration: none;
	display: inline-block;
	padding: 7px 0;
	height: 40px;
	width: 110px;
	text-align: center;
	border: 1px solid #2386C8;
	margin-right: -5px;
	background: #ffffff;
}

.mail-list-left .mail-nav a.active {
	background: #2386C8;
	color: #ffffff;
	width: 102px;
}

.mail-list-left .active {
	background-color: #ffffff;
}

.mail-list-left .active .msg-thumb-inner {
	border: none;
}

.mail-list-left .msg-thumb-inner .sender-name-left {
	float: left;
	width: 200px;
}

.mag-thumb-left {
	padding: 10px 20px;
	background: #EEF1F2;
}

.mag-thumb-left img {
	width: 54px;
	height: 54px;
	float: left;
	margin-right: 10px;
}

.mag-thumb-left a {
	font-size: 18px;
	color: #2c3e50;
	line-height: 34px;
	overflow: hidden;
	max-height: 34px;
	display: block;
}

.msg-thumb-inner {
	border-bottom: 1px solid #DADFE1;
	padding: 11px 0;
}

.mag-thumb-left p {
	font-size: 12px;
	color: #666666;
	margin-bottom: 0;
}

.mag-thumb-left p span {
}

.mail-thumb-cntrols {
	float: right;
	position: relative;
	top: 10px;
}

.mail-thumb-cntrols>a {
	width: 20px;
	height: 20px;
	display: block;
	float: left;
	margin-left: 7px;
	background: url(../img/profile-sprite.png);
}

.mail-thumb-cntrols>a.m-thum-save {
	background-position: -2px -426px;
}

.mail-thumb-cntrols>a.m-thum-save:hover {
	background-position: -38px -397px;
}

.mail-thumb-cntrols>a.m-thum-reply {
	background-position: -41px -474px;
}

.mail-thumb-cntrols>a.m-thum-reply:hover {
	background-position: -41px -352px;
}

.mail-thumb-cntrols>a.m-thum-del {
	background-position: -26px -425px;
}

.mail-thumb-cntrols>a.m-thum-del:hover {
	background-position: -38px -375px;
}

.msg-body {
	background-color: #EEF1F2;
	padding: 20px;
	min-height: 527px;
}

.msg-body h2.content-heading {
	margin-top: 0;
}

.convers-body {
	height: 340px;
	overflow-y: scroll;
	margin-bottom: 6px;
	padding-right: 10px;
	margin-right: -10px;
}

.mBoday-to {
	margin-bottom: 20px;
}

.mBoday-to img {
	width: 54px;
	height: 54px;
	float: left;
	margin-right: 14px;
}

.mBoday-to .msg-text-body {
	float: left;
	width: 70%;
	background: #ffffff;
	padding: 13px;
	overflow-wrap: break-word;
}

.mBoday-to .msg-text-body p {
	font-size: 14px;
}

.mBoday-to .msg-text-body span a {
	color: #ff0080;
}

.mBoday-from .msg-text-body span a {
	color: #fff;
	text-decoration: underline;
}

.mBoday-to .msg-text-body span.msg-dTime {
	color: #666666;
	float: right;
	font-size: 12px;
}

.mBoday-from {
	margin-bottom: 20px;
}

.mBoday-from img {
	width: 54px;
	height: auto;
	float: right;
	margin-left: 14px;
}

.mBoday-from .msg-text-body {
	float: right;
	width: 70%;
	background: #18BC9C;
	padding: 13px;
	color: #ffffff;
	overflow-wrap: break-word;
}

.mBoday-from .msg-text-body p {
	font-size: 14px;
}

.mBoday-from .msg-text-body span.msg-dTime {
	color: #ffffff;
	float: right;
	font-size: 12px;
}

.type-send-msg {
	margin-top: 25px;
}

.type-send-msg .media-list {
	margin-top: 10px;
}

.type-send-msg .media-list .media-list-box {
	display: inline-block;
	margin-right: 10px;
	position: relative;
}

.type-send-msg .media-list .media-list-box span {
}

.type-send-msg .media-list .media-list-box a {
	font-size: 11px;
	line-height: 13px;
	width: 14px;
	height: 14px;
	text-align: center;
	background: #00cc99;
	color: #fff;
	border-radius: 100%;
	display: inline-block;
	margin-left: 3px;
}

.type-send-msg input[type="text"] {
	width: 100%;
	height: 46px;
	position: relative;
	padding: 5px 194px 5px 16px;
	border: 1px solid #A9AEB1;
}

.type-send-msg a#add-conversation-attachments {
	display: block;
	background: url(../img/profile-sprite.png);
	width: 30px;
	height: 30px;
	float: right;
	top: -36px;
	position: relative;
	right: 14px;
	background-position: 0px -206px;
}

.type-send-msg button {
	float: right;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #00AC8F;
	background: #18BC9C;
	color: #ffffff;
	position: relative;
	right: 3px;
	top: -43px;
	font-size: 18px;
	height: 40px;
	font-weight: bold;
	width: 140px;
	text-align: center;
}
.convers-body::-webkit-scrollbar {
	width:4px;
	background-color:#18BC9C;
	position:relative;
	right:-10px;
}
.convers-body::-webkit-scrollbar-thumb {
	border-radius:10px;
	-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
	background-color:#555;
}

.b-type-info {
	padding: 0;
	clear: both;
}

.b-type-info p {
	background: #D8DEE1;
	margin: 0;
	padding: 1px 20px;
	height: 22px;
}

.b-type-info p span {
	font-size: 12px;
	color: #666666;
	float: left;
	padding: 2px 0;
}

.b-type-info a {
	display: block;
	height: 18px;
	width: 18px;
	background: url(../img/profile-sprite.png);
	float: right;
	background-position: -5px -314px;
}

.profile-gallery {
	margin-bottom: 60px;
}

span.M-icon {
	display: block;
	background: url(../img/profile-sprite.png);
	height: 36px;
	width: 36px;
	position: absolute;
	top: 10px;
	right: 20px;
	background-position: -3px -457px;
}

.profile-popover {
	padding: 60px 0
}

.popover-left {
	background: #F6F8F8;
	border: 1px solid #D4D6D8;
	/*width: 204px;*/
	padding: 20px;
}

.popover-left .user-info {
}

.popover-left .user-info img {
	height: auto;
	width: 100%;
}

.popover-left .user-info h2 {
	text-transform: uppercase;
	font-size: 24px;
	font-weight: bold;
	word-wrap: break-word;
}

.popover-left .user-info .user-age {
	font-weight: bold;
}

.popover-left .user-info .user-address {
	font-weight: bold;
}

.popover-left .user-info > a {
	padding: 16px 6px 0px 0px;
	padding-left: 0;
	color: #2386C8;
	font-weight: bold;
	display: block;
	float: left;
	font-size: 14px;
}

.popover-left .user-info > a:last-child {
	padding-right: 0;
}

.popover-right {
	width: 846px;
	padding: 0;
}

.popover-right .popover-image {
}

.popover-right .popover-image > img {
	width: 100%;
}

.popover-right .popover-image .comm-lower-btns {
	width: 846px;
	margin-left: 0;
	border: 0;
	background: rgba(238, 241, 242, 0.83);
	margin-top: -40px;
	position: relative;
	margin-bottom: 0;
}

.popover-right .popover-image .comm-lower-btns .right-btns {
	padding-right: 20px;
}

.popover-right .popover-image .comm-lower-btns .left-btns {
	padding-left: 20px;
}

.popover-right .popover-image .comm-lower-btns .left-btns a.heart {
	background-position: -3px -369px;
}

.popover-right .popover-image .comm-lower-btns .left-btns a.heart.liked {
	background-position: -4px -397px;
}

.cap-popover-img {
	padding: 10px 12px;
	border-bottom: 1px solid #A9AEB1;
}

.cap-popover-img p {
	margin-bottom: 0;
}

.popover-comnts {
	border: 1px solid #A9AEB1;
	margin-top: 30px;
	background: #EEF1F2;
	padding: 15px 14px;
}

.popover-comnts .popover-a-comnt {
	padding-bottom: 0;
	margin-bottom: 12px;
}

.popover-a-comnt img {
	float: left;
	width: 54px;
	height: 54px;
	margin-right: 12px;
}

.popover-a-comnt .a-comnt-text {
	float: left;
	width: 750px;
}

.popover-a-comnt .a-comnt-text h3 {
	margin: 0;
	font-size: 14px;
	font-weight: bold;
}

.popover-a-comnt .a-comnt-text p {
	font-size: 14px;
	margin: 0;
	line-height: 18px;
}

.popover-a-comnt .a-comnt-text span {
	float: right;
	color: #666666;
}

.popover-a-comnt .cb {
	border-bottom: 1px solid #A2A8AC;
	padding-bottom: 12px;
}

.popover-comnts .type-send-msg {
}

.popover-comnts .type-send-msg input[type="text"] {
	width: 100%;
}

.popover-comnts .type-send-msg button {
	margin-bottom: -24px;
}

.no-conversation-selected {
	text-align: center;
	font-size: 24px;
	color: #d8dee1;
	border-top: 1px solid #d8dee1;
	border-bottom: 1px solid #d8dee1;
	line-height: 50px;
	margin-top: 200px;
}

.have-no-conversation {
	text-align: center;
	font-size: 24px;
	color: #d8dee1;
	border-top: 1px solid #d8dee1;
	border-bottom: 1px solid #d8dee1;
	line-height: 50px;
	padding: 0 10px;
}

#conversation-new-message {
	position: relative;
}

#conversation-new-message input[type="file"] {
	width: 1px;
	height: 1px;
	position: absolute;
	top: 0;
}

.form-section .multiselect-inside {
	overflow: visible;
}

.form-section .multiselect-inside .multiselect, .ms-choice {
	/*border-radius:0;
  border: 1px solid #D8DEE1;
  width: 262px;
  text-align: left;
  background: #fff url(../img/input-sprite.png) no-repeat; border-radius: 0;
  background-position: 224px -81px;*/
	background: #fff url("../img/input-sprite.png") no-repeat scroll 224px -81px;
	border: 1px solid #D8DEE1;
	border-radius: 0;
	height: 40px;
	line-height: 27px;
	overflow: hidden !important;
	padding-right: 30px;
	text-align: left;
	width: 262px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.multiselect-container label.checkbox {
	position: static;
	padding: 0px;
}

.multiselect-container input[type="checkbox"] {
	width: auto;
	height: auto;
	position: static;
	margin-left: 18px;
	-webkit-appearance: checkbox;
	-moz-appearance: checkbox;
	appearance: checkbox;
}

b.caret {
	display: none;
}

.nav-right {
}

.loggedin {
	margin-top: -40px;
	padding-left: 0;
	padding-right: 0 !important;
}

ul.nav-right li.h-notifications {
	display: inline-block;
	padding: 16px 10px 7px 10px;
	background: #dadfe1;
	margin-top: -11px;
	float: left;
	margin-right: 2px;
}

ul.nav-right li.h-notifications>ul {
	padding-left: 0;
	list-style: none;
}

ul.nav-right li.h-notifications>ul>li {
	display: inline-block;
	margin-left: 0 !important;
	margin-bottom: -7px;
}

ul.nav-right li.h-notifications>ul>li>a {
	display: block;
	background: url(../img/profile-sprite.png);
	width: 30px;
	height: 30px;
	float: left;
}

ul.nav-right li.h-notifications>ul>li>span {
	background: #18bc9c;
	color: #ffffff;
	height: 20px;
	min-width: 20px;
	float: left;
	text-align: center;
	border-radius: 14px;
	margin-left: -14px;
	margin-top: -9px;
	padding: 0 5px;
}

ul.nav-right li.h-notifications>ul>li>a.h-msgs {
	background-position: 0px -495px;
}

ul.nav-right li.h-notifications>ul>li>a.h-noti {
	background-position: 0px -521px;
}

ul.nav-right li.h-notifications>ul>li>a.h-cart {
	background-position: 0px -547px;
}

ul.nav-right li.h-notifications>ul>li>a.h-msgs:hover {
	background: url(../img/profile-sprite-h.png) 1px 0px;
}

ul.nav-right li.h-notifications>ul>li>a.h-noti:hover {
	background: url(../img/profile-sprite-h.png) 1px -26px;
}

ul.nav-right li.h-notifications>ul>li>a.h-cart:hover {
	background: url(../img/profile-sprite-h.png) 1px -52px;
}

ul.nav-right li.user-name-drowpd {
	display: inline-block;
	margin-left: 0px !important;
	background: #dadfe1;
	float: left;
	margin-top: -11px;
	height: 51px;
	margin-right: 2px;
}

ul.nav-right li.user-name-drowpd a {
	display: block;
	padding: 13px 10px;
	font-size: 18px;
	color: #2C3E50;
	text-transform: capitalize;
}

ul.nav-right li.user-name-drowpd a:hover {
	text-decoration: none
}

ul.nav-right li.user-name-drowpd > a:hover {
	color: #ff0080;
}

.navbar-right .dropdown-menu {
	padding: 10px;
}

/*.messages-page .col-md-8 {
	float: right;
	padding-left: 0;
}*/

ul.nav-right li.h-search {
	display: inline-block;
	float: left;
	margin-top: -11px;
	margin-left: 0px !important;
	height: 51px;
	width: 49px;
	padding: 15px 12px;
	background: #DADFE1;
}

ul.nav-right li.h-search>a {
	display: block;
	background: url(../img/main-sprite.png);
	height: 25px;
	width: 25px;
}

ul.nav-right li.h-search>a:hover {
	background-position: -26px 0px;
}

.navbar .dropdown ul.dropdown-menu li {
	margin: 0;
}

.navbar .dropdown ul.dropdown-menu li a {
	min-width: 177px;
	padding: 5px;
	font-size: 14px;
}

.navbar .dropdown ul.dropdown-menu li a:hover {
	background: #18BC9C;
	color: #fff;
}

.location_fields_wrap > div {
	clear: both;
}

button.add_location_button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 0;
	background: #dadfe1;
	float: right;
	margin-right: 30px;
}

.need-detail-right {
}

.need-box-top {
	border: 1px solid #a9aeb1;
	background: #f6f8f8;
}

.need-box-top:after {
	content: "";
	display: block;
	clear: both;
}

.need-box-topl {
	float: left;
	width: 53%;
	height: auto;
	overflow: hidden;
	border: 1px solid #d4d6d8;
	border-left: 0;
	border-top: 0;
}

.need-box-topl img {/* max-width: 100%; */
	height: auto;
}

.box-top-left {
	float: left;
	width: 47%;
	padding: 12px 12px 0px 12px;
	background: #f6f8f8;
	/* border: 1px solid #d4d6d8; */
	min-height: 255px;
}

.box-top-left h2 {
	font-size: 24px;
	font-weight: bold;
	color: #333366;
	margin-top: 0;
	margin-bottom: 0px;
}

.box-top-left .tableType {
	float: left;
	margin-top: 10px;
}

.box-top-left .tableType .tableTypeRow {
	height: 30px;
	color: #666666;
}

.tableType .tableTypeRow div {
	float: left;
	width: 180px;
	text-align: left;
	border-bottom: 1px solid #a6abae;
	overflow: hidden;
	height: 30px;
}

.tableType .tableTypeRow .box-left {
	padding: 5px 0px;
	border-right: 1px solid #a6abae;
}

.tableType .tableTypeRow .box-right {
	padding: 5px 0px 5px 10px;
}

.tableType .tableTypeRow .box-right-last {
	border-bottom: 0;
}

.tableType .tableTypeRow .box-left-last {
	border-bottom: 0;
}

.need-box-bottom {
	clear: both;
}

.need-box-bottom a.applynowbtn {
	background: #18bc9c;
	color: #fff;
	border: 1px solid #00ac8f;
	display: block;
	float: right;
	margin-top: 30px;
	width: 144px;
	height: 40px;
	text-align: center;
	line-height: 38px;
	font-size: 18px;
	font-weight: bold;
}

.need-box-bottom a.applynowbtn:focus {
	outline: 0;
	text-decoration: none;
}

.need-box-bottom a.applynowbtn:hover {
	text-decoration: none;
	background: #ff0080;
	border: 1px solid #ff0080
}


.need-box-bottom .helps-likes-badge {
	background-color: #ECF0F1;
	position: relative;
	right: 1px;
	width: 55px;
	height: 55px;
	float: left;
	margin: 20px 20px 15px 0px;
	color: #ffffff;
	text-align: center;
	line-height: 18px;
	padding: 3px;
}

.need-box-bottom .helps-likes-badge span {
	width: 50px;
	height: 50px;
	background: url(../img/badge1.png) 0 0 no-repeat;
	display: block;
}

.badge1 {
	background: url(../img/badge1.png) 0 0 no-repeat !important;
}

.badge2 {
	background: url(../img/badge2.png) 0 0 no-repeat !important;
}

.badge3 {
	background: url(../img/badge3.png) 0 0 no-repeat !important;
}

.badge4 {
	background: url(../img/badge4.png) 0 0 no-repeat !important;
}

.need-box-bottom .helps-likes {
	float: left;
	margin-left: 0;
	margin-right: 10px;
	padding-top: 8px;
	cursor: default;
}

.need-box-bottom button {
}

.need-details-left {
	margin-top: 30px;
}

.need-description {
	clear: both;
	padding: 20px 15px;
	border-bottom: 1px solid #70787D;
	overflow-wrap: break-word;
}

.need-description p {
	margin: 0;
	line-height: 16px;
	color: #2C3E50;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}

.needprojectdetail {
	margin-top: 30px;
}

.needpd-l {
	width: 30%;
	float: left;
	overflow: hidden;
}

.needpd-l img {
	width: 100%;
	height: auto;
}

.needpd-r {
	padding-left: 15px;
	float: left;
	width: 70%;
	overflow-wrap: break-word;
}

.needpd-r h3 {
	font-size: 24px;
	font-weight: bold;
	margin: 0;
}

.needpd-r p {
	color: #666666;
	margin: 0 0 5px;
	font-size: 14px;
}

.statusbar {
	clear: both;
	width: 100%;
	position: relative;
	height: 21px;
	background: #ecf0f1;
}

.statusbar .barforcust {
	height: 21px;
	position: absolute;
	left: 0;
	top: 0;
	background: #2386c8;
}

.needpd-rb {
	clear: both;
	margin-top: 3px;
}

.needpd-rb-l {
	float: left;
	color: #666666;
}

.needpd-rb-r {
	float: right;
}

.needpd-rb-r a {
	text-transform: uppercase;
	font-weight: bold;
}

.applytothisneed {
	clear: both;
	padding-top: 60px;
}

.applytothisneed h2.content-heading {
	margin: 0 0 30px 0px;
}

.applytothisneed .a-commented {
	margin-left: 0;
	width: 100%;
	border-top: 1px solid #a9aeb1;
	margin-bottom: 30px;
}

.similar-needs {
	margin-top: 30px;
	/*width: 277px;*/
	padding-right: 0;
	margin-bottom: 30px;
}

.similar-needs h2.content-heading {
	margin-top: 0;
	clear: both;
}

.needs-right-col {
}

.needs-right-col .box-004 {
	margin-bottom: 30px;
}

.needs-right-col .box-004 >a {
	display: block;
	height: 167px;
	overflow: hidden;
}

.box-004 h2.shop-box-title {
	font-size: 18px;
	padding: 0;
	border: 0;
	margin: 0;
	overflow: hidden;
	/*text-overflow: ellipsis;*/
	/*display: -webkit-box;*/
	line-height: 22px;
	height: 46px;/*-webkit-line-clamp: 2;
    -webkit-box-orient: vertical;*/
}

.box-004 h2.shop-box-title span {
	font-size: 14px;
	font-weight: normal;
	color: #666666;
	margin-left: 10px;
}

.box-004 .author-detail {
}

.box-004-lower {
	padding: 10px;
	border: 1px solid #A9AEB1;
}

.box-004-auth {
	border-bottom: 3px solid #18bc9c;
	width: 100%;
	padding: 10px;
	height: 55px;
	overflow: hidden;
}

.box-004-auth a {
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}

.box-004-auth span {
	margin-left: 10px;
}

.box-004-lower .need-box-bottom {
	padding-top: 10px;
}

.box-004-lower .need-box-bottom .helps-likes {
	float: right;
	margin: 0;
	overflow: hidden;
}

.box-004-lower .need-box-bottom .helps-likes-badge {
	float: right;
	margin: 0;
	margin-right: 7px;
}

.box-004-lower .need-box-bottom p {
	float: left;
	color: #666666;
	width: 118px;
	max-height: 60px;
	overflow: hidden;
	margin: 0;
}

.box-004-lower .post-date {
	background: #E9EBED;
	width: 100%;
	overflow: hidden;
	padding: 0 10px;
	margin-top: 10px;
}

.box-004-lower .post-date p {
	float: left;
	margin: 0;
	padding: 3px 0;
	background: none;
	font-weight: bold;
	width: 200px;
	max-height: 26px;
	overflow: hidden;
}

.box-004-lower .post-date a {
	float: right;
	padding: 3px 0;
	text-decoration: underline;
}

.box-004-lower a.applynowbtn {
	background: #18bc9c;
	color: #fff;
	border: 1px solid #00ac8f;
	display: block;
	margin-top: 10px;
	height: 40px;
	text-align: center;
	line-height: 38px;
	font-size: 18px;
	font-weight: bold;
}
;
}

.box-004-lower a.applynowbtn:hover {
	text-decoration: none;
	background-color: #ff0080;
	border: 1px solid #ff0080;
}

.pDetail-banner .pDetail-banner-img a.applynowbtn {
	color: #ffffff;
	display: inline-block;
	width: 110px;
	margin-left: 11px;
	margin-right: 0;
	text-decoration: none;
	float: right;
}

a.applynowbtn {
	background: #18bc9c;
	color: #fff;
	border: 1px solid #00ac8f;
	display: inline-block;
	margin-top: 10px;
	height: 40px;
	text-align: center;
	line-height: 38px;
	font-size: 18px;
	font-weight: bold;
}

a.applynowbtn:hover {
	text-decoration: none;
	background: #ff0080;
	border: 1px solid #ff0080;
}

.modal-dialog .modal-body input[type="checkbox"].realchkbx:checked + .visvchkbx {
	background-position: -72px -91px;
}

.pDetail-banner {
	clear: both;
}

.pDetail-banner .pDetail-banner-img img {
}

.pDetail-banner .pDetail-banner-img {
	height: 400px;
	position: relative;
	background: #2386C8;
	overflow: hidden;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details {
	width: 264px;
	position: absolute;
	background: rgba(255, 255, 255, 0.9);
	padding: 20px;
	right: 0;
	top: 0;
	z-index: 999;
}

.pDetail-banner-details a.btn-complete {
	background: #18bc9c;
	color: #fff;
	border: 1px solid #00ac8f;
	display: block;
	margin-top: 10px;
	height: 40px;
	text-align: center;
	line-height: 38px;
	font-size: 18px;
	font-weight: bold;
}

.pDetail-banner-details a.btn-complete:hover {
	text-decoration: none;
	background: #ff0080;
	border: 1px solid #ff0080;
}

.pDetail-banner-details a.btn-complete:focus {
	text-decoration: none;
	outline: 0;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details h3 {
	font-size: 18px;
	margin: 0;
	font-weight: bold;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .statusbar {
	margin: 15px 0;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details h2 {
	font-size: 22px;
	text-align: center;
	font-weight: bold;
	color: #2386c8;
	margin-top: 10px;
	margin-bottom: 10px;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info {
	background: #ffffff;
	width: 264px;
	margin-left: -20px;
	padding: 10px 0px 10px 20px;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info h4 {
	margin-bottom: 0px;
	margin: 0;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info h4 a {
	font-weight: bold;
	font-size: 14px;
	text-transform: uppercase;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info p {
	font-size: 14px;
	color: #2c3e50;
	margin: 0;
	line-height: 18px;
	font-weight: bold;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info-low {
	padding-top: 20px;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info-low p {
	font-weight: bold;
	font-size: 14px;
	color: #2c3e50;
	margin-bottom: 4px;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info-low .links {
	float: left;
	margin-top: 22px;
}

div.pDetail-banner-details .author-info-low a {
	color: #2c3e50;
	text-decoration: underline;
	display: inline;
	margin-right: 8px;
}

.pDetail-banner .pDetail-banner-img .banner-heading {
	background-image: url(../img/banner-heading-bg.png);
	height: 246px;
	z-index: 99;
	position: absolute;
	background-repeat: repeat-x;
	background-position: bottom;
	background-color: transparent;
	width: 100%;
	padding-top: 84px;
	padding-left: 28px;
	bottom: 0;
}

.pDetail-banner .pDetail-banner-img .banner-heading h1 {
	margin: 0;
	color: #ffffff;
	text-shadow: 2px 0px 2px #000000;
	font-size: 36px;
	font-weight: bold;
	width: 100%;
	padding-right: 267px;
	max-height: 78px;
	overflow: hidden;
	overflow-wrap: break-word;
}

.verif-icon {
	width: 30px;
	height: 30px;
	background: url(../img/profile-sprite.png);
	display: inline-block;
	background-position: -35px -523px;
}

.pDetail-banner .pDetail-banner-img .banner-heading h1 span.verif-icon {
	top: 5px;
	position: relative;
}

.offer-details .odbelow .offerD-left .detail-text a.applynowbtn {
	padding: 10px 20px;
	margin-top: 0;
	height: inherit;
	line-height: 24px;
	float: right;
}

.pDetail-banner .pDetail-banner-img .banner-heading p {
	font-size: 18px;
	font-weight: bold;
	margin-top: 7px;
	color: #ffffff;
	height: 50px;
	width: 705px;
	overflow-wrap: break-word;
	overflow: hidden;
}

.proj-detail-low-info-top {
	border-top: 1px solid #989EA1;
	border-bottom: 1px solid #989EA1;
	background: #F6F8F8;
}

.proj-detail-low-info {
	padding: 10px 15px;
}

.proj-detail-low-info .low-info-box {
	margin-bottom: 10px;
}

.proj-detail-low-info .low-info-box p {
	color: #666666;
	font-size: 18px;
	margin: 0;
}

.proj-detail-low-info .low-info-box p span {
	color: #2c3e50;
}

.proj-detail-low-info .low-info-box p span a.low-info-weblink {
	color: #2c3e50;
	text-decoration: none;
}

.project-mission-scor {
	border: 1px solid #A9AEB1;
	background: #F6F8F8;
	margin-top: 30px;/*width: 1140px;*/
}

.project-mission-scor .project-mission-points {
	padding: 10px;
	background: #2386C8;
}

.project-mission-scor .project-mission-points .points {
	border-right: 1px solid #ecf0f1;
	padding: 6px 0;
}

.project-mission-scor .project-mission-points .points:last-child {
	border-right: 0;
}

.project-mission-scor .project-mission-points .points p {
	text-align: center;
	color: #ffffff;
}

.project-mission-scor .project-mission-points .points p span {
	font-size: 24px;
	font-weight: normal;
}

.project-mission-scor .project-mission-text {
	padding: 23px;
}

.project-mission-scor .project-mission-text h2 {
	margin: 0;
	color: #2c3e50;
	margin-bottom: 15px;
	font-size: 18px;
	text-transform: uppercase;
}

.project-detail-edit .content-left {
	padding: 60px 15px;
}

.project-detail-edit .content-left h2.content-heading {
}

.project-mission-scor .project-mission-text p {
}

.project-edit-timeline .content-heading {
	margin-top: 0;
}

.project-needs-area {
	margin-top: 60px;
}

.project-needs-area h2.content-heading {
	margin-top: 0;
	/*width: 1140px;*/
	margin-left: 15px;
}

.box-004 {
	margin-bottom: 20px;
}

.box-004 > a {
	display: block;
	max-height: 168px;
	overflow: hidden;
}

.box-008 {
	display: block;
	overflow: hidden;
}

.box-004 > a > img {
	height: auto;
	width: 100%;
	min-width: 100%;
}

.box-004 > a > img.landscape {
	height: auto;
	width: 100%;
}

.offer-details {
}

.offer-details .image-section {
}

.offer-details .image-section img {
}

.offer-details .image-section h2 {
	font-size: 24px;
	font-weight: bold;
}

.offer-details .odbelow {
}

.offer-details .odbelow .offerD-left {
	width: 70%;
	float: left;
}

.odbelow .offerD-left .offerD-left-info {
	border-bottom: 1px solid #999999;
	padding-bottom: 15px;
}

.odbelow .offerD-left .offerD-left-info p {
	margin-bottom: 2px;
}

.odbelow .offerD-left .offerD-left-info p span {
}

.offer-details .odbelow .offerD-left .detail-text {
	margin-top: 20px;
	padding-bottom: 38px;
	border-bottom: 1px solid #999999;
	overflow-wrap: break-word;
}

.offer-details .odbelow .offerD-left .detail-text .helps-likes {
	float: left;
	margin-top: 0;
	padding: 8px;
	margin-left: 0;
	margin-right: 10px;
}

.offer-details .odbelow .offerD-left .detail-text .helps-likes span {
	margin-left: -8px;
}

.offer-details .odbelow .offerD-left .detail-text p {
	line-height: 16px;
}

.offer-details-whole .user-cont-right {
	padding-left: 15px;
	padding-right: 15px;
}

.offer-details .odbelow .offerD-left .time-posted {
	padding: 10px 0;
	border-bottom: 1px solid #999999;
}

.offer-details .odbelow .offerD-left .time-posted p {
	margin-bottom: 0;
	font-weight: bold;
	color: #666666;
}

.offer-details .odbelow .offerD-right {
	float: right;
	width: 27%;
}

.offer-details .odbelow .offerD-right .popover-left {
}

.offer-details .a-commented {
	width: 100%;
	margin-left: 0;
	border-top: 1px solid #A9AEB1;
}

.offer-details .content-heading {
	margin-top: 0;
}

.form-section .add-section select.grey, .new_added_location select {
	color: rgb(176, 176, 176);
}

.karma-shop-head {
	background: #2c3e50;
	border-bottom: 0;
}

.karma-shop-head .msg-header {
	padding: 0px 15px;
}

.karma-shop-head-right {
	float: left;
	width: 20%;
}

.karma-shop-head-right a:hover {
	text-decoration: none;
}

.karma-shop-head-right a {
	color: #ffffff;
	display: block;
	background: #202e3b;
	float: left;
	font-size: 24px;
	text-transform: uppercase;
	padding: 32px 12px;
}

.shop-search-top {
	float: left;
	float: left;
	padding: 25px 0;
	width: 80%;
}

.shop-search-top input[type="text"] {
	background: transparent;
	box-shadow: none;
	border: 1px solid #999999;
	padding: 9px;
	color: #ffffff;
	font-size: 20px;
	border-right: 0;
	border-radius: 0;/* height: inherit; */
}

.shop-search-top a.adv-search {
	font-size: 14px;
	color: rgba(236, 240, 241, 0.7);
	text-decoration: underline;
	padding: 12px 3px;
	float: left;
}

.shop-search-top input[type="button"] {
	background: Transparent;
	border: 1px solid #18BC9C;
	color: #ECF0F1;
	font-weight: bold;
	padding: 6px 0px;
	font-size: 24px;
	text-shadow: none;
	width: 209px;
	margin-left: 0;
}

.shop-search-top input[type="button"]:hover {
	text-decoration: none;
	border: 1px solid #ff0080;
}

.karma-shop-nav {
	position: relative;
	/* height: 87px; */
	border-top: 3px solid #2386c8;
	margin-top: 27px;
	margin-bottom: 0px;
}

.karma-shop-nav ul.shop-nav {
	list-style: none;
	padding-left: 0;
	text-align: center;
	margin: 0;
	margin-top: -22px;
}

.karma-shop-nav ul.shop-nav li {
	display: inline-block;
	border: 1px solid #2386c8;
	margin-left: -5px;
	overflow: hidden;
}

.karma-shop-nav ul.shop-nav li:hover {
	border-color: #ff0080;
}

.karma-shop-nav ul.shop-nav li.active a {
	background: #2386c8;
	color: #ffffff;
}

.karma-shop-nav ul.shop-nav li.active a:focus {
	text-decoration: none;
}

.karma-shop-nav ul.shop-nav li a {
	font-size: 18px;
	color: #2c3e50;
	display: block;
	padding: 7px 12px;
	background: #ffffff;
}

.karma-shop-nav ul.shop-nav li a:focus {
	text-decoration: none;
}

.karma-shop-nav ul.shop-nav li a:hover {
	text-decoration: none;
}

.shop-karma-left-content {
	margin-top: 30px;
}

.shop-karma-left-content .shop-needs {
	/* margin-top: 30px; */
}

.shop-karma-right-content {
	margin-top: 30px;
	background: rgba(236, 240, 241, 0.9);
	padding-bottom: 30px;
	margin-bottom: 60px;
	overflow: hidden;
}

.shop-karma-right-content .box-004-lower {
	background: #ffffff;
}

.shop-karma-right-content h2.content-heading {
	margin-top: 24px;
}

.shop-karma-right-content .shop-needs {
	margin-top: 30px;
}

.shop-search-mid {
	width: 72%;
	float: left;
}

.shop-search-mid input[type="text"].form-control {
	height: 48px;
	border-right: 1px solid #999999;
}

.shop-search-mid .input-group-btn {
	height: 48px;
}

.shop-search-mid .form-control {
	height: 48px;
}

.shop-search-mid .input-group-btn {
	border-radius: 0;
}

.shop-search-mid .input-group-btn .btn {
	padding: 9px;
	border-radius: 0;
	background: transparent;
	color: #ffffff;
	font-size: 20px;
	color: rgba(236, 240, 241, 0.7);
	border-color: #999999;
	border-left: 0;
	height: 48px;
}

.shop-search-mid .input-group-btn ul {
}

.shop-search-mid .input-group-btn ul li {
}

.shop-search-mid .input-group-btn ul li a {
}

.karma-shop-head-low {
	background: #202e3b;
	padding: 29px;
}

.karma-shop-head-low .extended-low {
	width: 100%;
	overflow: hidden;
	border: 1px solid;
	border-color: #999999;
	padding: 0;
	margin-right: 20px;
}

.karma-shop-head-low .extended-low select {
	width: 120%;
	background-position: 320px -110px;
	background-color: #2c3e50;
	color: #ffffff;
	border-color: #999999;
	color: rgba(236, 240, 241, 0.7);
	border: 0;
}

.shop-karma-left-content a.load-more {
	display: block;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	background: #666666;
	color: #ffffff;
	text-decoration: none;
	padding: 6px;
	height: 36px;
	width: 848px;
	float: left;
	margin-top: 30px;
}

.search-r {
	margin-top: 30px;
}

.pagination {
	width: 100%;
	text-align: center;
}

.pagination ul.pagesnav {
	text-align: center;
	display: inline;
}

.pagination ul.pagesnav li {
	display: inline-block;
	text-align: center;
}

.pagination ul.pagesnav li a {
	border-radius: 0;
}

.pagination ul.pagesnav li.first {
}

.pagination ul.pagesnav li.back {
}

.pagination ul.pagesnav li.pageno {
}

.pagination ul.pagesnav li.next {
}

.pagination ul.pagesnav li.last {
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
	background-color: #2c3e50;
	border-color: #2c3e50;
}

.pagination>li>a, .pagination>li>span {
	padding: 5px 12px;
}

.home-feeds {
}

.home-feeds .left-nav {
	list-style: none;
	padding-left: 0;
	margin: 0;
	background: #eef1f2;
	padding: 10px;
	margin-bottom: 20px;
}

.home-feeds .left-nav li {
	margin-bottom: 10px;
}

.home-feeds .left-nav li a {
	font-size: 15px;
	color: #2c3e50;/* line-height: 38px; */
}

.home-feeds .left-nav li a:hover {
}

.a-home-feed .a-feed-top img {
	width: 54px;
	float: left;
	margin-right: 10px;
	border: 1px solid #ccc;
	padding: 1px;
}

.a-feed-mid p {
	text-align: justify;
	padding-top: 10px;
}

.a-home-feed {
	padding: 10px;
	border: 1px solid #a9aeb1;
	margin-bottom: 30px;
	overflow: hidden;
}

.a-home-feed .a-feed-top {
	margin-bottom: 0;
}

.a-home-feed .say-area {
	padding: 0;
}

.a-home-feed .a-feed-top p {
}

.a-home-feed .a-feed-top p a {
	color: inherit;
	font-weight: bold;
}

.a-home-feed .a-feed-mid {
	overflow-wrap: break-word;
}

.a-home-feed .a-feed-mid p {
}

.a-home-feed .a-feed-mid img {
}

.a-feed-mid img {
	float: none !important;
}

.a-feed-low {
	margin-bottom: -8px;
	width: inherit;
	margin-left: 0;
	overflow: hidden;
}

.home-feeds .load-more {
	display: block;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	background: #666666;
	color: #ffffff;
	text-decoration: none;
	padding: 6px;
	height: 36px;
	float: left;
	display: block;
	width: 100%;
	margin-bottom: 30px;
}

.home-feeds .comm-lower-btns .left-btns {
	padding-left: 0;
}

/*Home Page Starts*/
.homepage-feed {
	padding: 10px;
	border: 1px solid #a9aeb1;
	margin-bottom: 30px;
}

.home-say-area {
	float: right;
	top: 0;
	width: 565px;
	border: 0px solid #D4D6D8;
	position: relative;
}

.home-say-area textarea {
	border: 0;
	resize: none;
	padding: 0;
}

.home-say-area textarea:focus {
	outline: 0;
}

.home-say-area input[type="file"] {
	width: 1px;
	height: 1px;
	position: absolute;
	top: 0px;
}

.home-say-area-icons {
	padding: 10px 0;
	border-top: 1px solid #a9aeb1
}

.home-say-area-icons a.say-link {
	display: block;
	width: 28px;
	height: 28px;
	float: left;
	background: url(../img/profile-sprite.png);
	margin-left: 5px;
	background-position: 0px -206px;
}

.home-say-area-icons a.say-photo {
	display: block;
	width: 28px;
	height: 28px;
	float: left;
	background: url(../img/profile-sprite.png);
	margin-left: 5px;
	background-position: 0px -247px;
}

.home-say-area-icons a.say-vid {
	display: block;
	width: 28px;
	height: 28px;
	float: left;
	background: url(../img/profile-sprite.png);
	margin-left: 5px;
	background-position: 0px -279px;
}

.home-say-area-icons .say-attach {
	float: right;
}

.a-home-feed .comment-from p {
	padding: 6px 0 0 59px;
	margin: 0;
	overflow: hidden;
	width: 100%;
}

.a-home-feed .comment-from p > span.only_comment_text {
	/*max-width: 500px;*/
	text-align: justify;
	display: inline-block;
}

/*commented box */
.a-commented-2 {
	float: left;
	width: 100%;
	padding: 15px;
	margin-top: 10px;
	border: 1px solid #A9AEB1 !important;
	border-top: 0;
	background: #EEF1F2;
	margin-bottom: 30px;
	overflow: hidden;
}

.a-commented-3 {
	float: left;
	width: 100%;
	padding: 15px;
	margin-top: 0px;
	border: 1px solid #A9AEB1;
	border-top: 0;
	background: #EEF1F2;
	overflow: hidden;
}

.a-commented-4 {
	float: left;
	width: 100%;
	padding: 15px;
	margin-top: 0;
	border: 1px solid #A9AEB1;
	border-top: 0;
	background: #EEF1F2;
	margin-bottom: 30px;
	overflow: hidden;
}

.show-all-comments {
	margin-top: 10px;
	float: left;
	width: 100%;
	padding: 5px 10px;
	border: 1px solid #A9AEB1;
	border-top: 0;
	background: #e9ebed;
}

.show-all-comments a {
	text-transform: uppercase;
	color: #18bc9c;
	text-decoration: none;
}

.box-type-4 {
	border: 1px solid #A9AEB1;
	padding: 0;
}

.box-type-4 ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.box-type-4 ul li > a {
	display: block;
	float: left;
	width: 298px;
	height: 190px;
	overflow: hidden;
}

.box-type-4 ul li > a > img {
	width: 100%;
	height: auto;
	max-height: 120%;
}

.box-type-4 ul li .btn {
	display: block;
	clear: both;
}

.box-type-4 ul li .karma-right {
	width: 330px;
	float: right;
	padding: 10px;
}

.box-type-4 ul li .karma-right h2.shop-box-title {
	font-size: 20px;
}

.box-type-4 ul li .post-date p {
	padding: 3px 10px;
}

/*Home Page Ends*/
.modal-dialog {
	width: 555px;
	color: #666666;
}

.modal-dialog .modal-header {
	border: 0;
	padding-bottom: 0;
}

.modal-dialog .modal-content {
	border-radius: 0;
	box-shadow: none;
	background: #eef1f2;
}

.modal-dialog .modal-header .close {
	opacity: 1;
}

.modal-dialog .modal-header .close:focus {
	outline: 0;
}

.modal-dialog .modal-title {
	font-size: 24px;
}

.modal-dialog .modal-body {
}

.modal-dialog .modal-body .vouch-form {
	margin-bottom: 0px;
	margin-top: 0px;
	min-height: 200px;
	padding: 0;
	height: 200px;
}

.modal-dialog .modal-body>p {
	margin-bottom: 20px;
}

.modal-dialog .modal-body .scrollable-area {
	background: #ffffff;
	width: 524px;
	height: 305px;
	overflow-y: scroll;
	padding: 15px;
	border: 1px solid #9da2a5;
}

.modal-dialog .modal-body .scrollable-area .checkbox {
	margin-top: 0;
	margin-bottom: 12px;
}

.modal-dialog .modal-body .scrollable-area .checkbox .mt8 {
	margin-top: 0;
	margin-right: 24px;
}

.modal-dialog .modal-body .scrollable-area .selectAll-boxs {
}

.modal-dialog .modal-body .scrollable-area ul.ctn-list {
	list-style: none;
	padding-left: 0;
}

.modal-dialog .modal-body .scrollable-area ul.ctn-list li {
	margin-bottom: 20px;
}

.modal-dialog .modal-body .scrollable-area ul.ctn-list li .mt8 {
	float: left;
	margin-top: 10px;
}

.modal-dialog .modal-body .scrollable-area ul.ctn-list li .mt8 .realchkbx {
}

.modal-dialog .modal-body .scrollable-area ul.ctn-list li .owener-img {
	width: 54px;
	height: 54px;
	float: left;
	margin-left: 28px;
}

.modal-dialog .modal-body .scrollable-area ul.ctn-list li .owener-img img {
	max-width: 100%;
}

.modal-dialog .modal-body .scrollable-area ul.ctn-list li p.authr-nam {
	float: left;
	padding: 16px;
	margin-bottom: 0;
}

.modal-inner-body .modal-body-section .indent-right .starP {
	font-style: italic;
}

.modal-inner-body .modal-body-section .indent-right .starP span {
	float: left;
	margin-right: 10px;
	color: #ff0080;
}

.modal-dialog .modal-body .visvchkbx {
	background-position: -72px -110px;
	width: 20px;
	height: 20px;
	margin-top: 2px;
}

.indent-right .selectinside {
	margin-bottom: 0;
	width: 260px;
	overflow: hidden;
	position: relative;
	display: inline-block;
	z-index: 0;
}

.indent-right .selectinside:before {
	display: block;
	position: absolute;
	content: '';
	right: 0;
	top: 0;
	height: 1em;
	width: 1em;
	margin: 2px;
	background: #ffffff;
	z-index: 5;
}

.modal-dialog .modal-footer .totalKPdiv {
	float: right;
}

.modal-dialog .modal-body .modal-inner-body {
	padding-left: 25px;
}

.modal-inner-body .modal-body-section {
	margin-bottom: 30px;
}

.modal-inner-body .modal-body-section a {
	color: #18bc9c;
	text-decoration: underline;
}

.modal-inner-body .modal-body-section .indent-right {
	padding-left: 45px;
}

.modal-inner-body .modal-body-section input[type="radio"].realradio {
	visibility: hidden;
	margin: 0;
	padding: 0;
}

.modal-dialog .modal-body input[type="checkbox"].realchkbx {
	visibility: hidden;
	margin: 0;
	padding: 0;
}

.modal-dd .selectinside {
	width: 262px;
	float: left;
	overflow: hidden;
	margin-bottom: 20px;
}

.modal-dd button.send-btn {
	margin-left: 30px;
	color: #ffffff;
	padding: 7px 40px;
}

.modal-dialog .modal-body .checkbox {
}

.modal-dialog .btn {
	border-radius: 0;
	font-weight: normal;
	border: 1px solid #f1f1f1;
	font-size: 18px;
	padding: 10px 20px;
	margin-left: 10px;
}

.modal-dialog .modal-footer {
	border: 0;
	clear: both;
}

.modal-dialog .modal-footer .totalKPdiv p.totalKP {
	padding: 0px;
	margin-bottom: 0;
	color: #666666;
	font-weight: bold;
	float: right;
	text-align: left;
}

.modal-dialog .modal-footer p.noOfpart {
	float: left;
	padding: 9px;
	margin-bottom: 0;
	color: #666666;
}

.modal-dialog .modal-footer .btn-primary, .modal-dd button.send-btn {
	font-weight: normal;
	background: #18bc9c;
	border: 0;
	font-size: 18px;
	float: right;
	margin-left: 10px;
	padding: 10px 20px;
}

.modal-header .close span:first-child {
	background: url(../img/close-btn-cros.png);
	display: block;
	width: 24px;
	height: 24px;
}

.account-heading h2 {
	margin: 10px 10px;
	font-size: 24px;
	font-weight: 300;
}

.account-heading {
	padding: 0;
}

.account-page p.karmapoints {
	text-align: right;
	font-size: 18px;
	font-weight: 300;
}

.account-page {
	margin-bottom: 20px;
}

.account-page .karma-shop-nav {
	margin-top: 35px;
	margin-bottom: 20px;
}

.tabs-content-body {
	padding: 20px;
	border: 1px solid #a9aeb1;
}

.tabs-content-body .tabs-content-body-table {
}

.table-striped>tbody>tr>td {
	height: 33px;
}

.tabs-content-body .tabs-content-body-table {
}

.table-responsive {
}

.tabs-content-body .tabs-content-body-table {
	/*margin: 20px;*/
}

.table-responsive .table-striped {
	color: #666666;
	font-weight: 300;
}

.table-bordered>thead>tr>th, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>tbody>tr>td, .table-bordered>tfoot>tr>td {
	border: 1px solid #a9aeb1;
}

.table-striped>tbody>tr:nth-child(odd)>td, .table-striped>tbody>tr:nth-child(odd)>th {
	background-color: #eef1f2;
}

.table-bordered {
	border: 1px solid #a9aeb1;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
	padding: 6px 10px;
	color: #666666;
}

.table>thead>tr>td.success, .table>tbody>tr>td.success, .table>tfoot>tr>td.success, .table>thead>tr>th.success, .table>tbody>tr>th.success, .table>tfoot>tr>th.success, .table>thead>tr.success>td, .table>tbody>tr.success>td, .table>tfoot>tr.success>td, .table>thead>tr.success>th, .table>tbody>tr.success>th, .table>tfoot>tr.success>th {
	background-color: #2386c8;
	color: #ffffff;
}

.account-page .col-md-10 {
	padding: 0;
}

.tabs-content-body .needprojectdetail {
	margin: 0;
	border-bottom: 1px solid #a9aeb1;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.tabs-content-body .needprojectdetail:last-child {
	border-bottom: 0;
	margin-bottom: 0;
}

.tabs-content-body .needprojectdetail .needpd-l {
	width: 24%;
}

.tabs-content-body .needprojectdetail .needpd-r {
	padding-left: 20px;
	width: 41%;
}

.tabs-content-body .needprojectdetail .needpd-r h3 {
}

.tabs-content-body .needprojectdetail .needpd-r p {
}

.tabs-content-body .needprojectdetail .needpd-r .statusbar {
}

.tabs-content-body .needprojectdetail .needpd-r .statusbar .completed {
	background: #18bc9c;
}

.tabs-content-body .needprojectdetail .needpd-r .statusbar .barforcust {
	max-width: 100%;
}

.tabs-content-body .needprojectdetail .needpd-r .needpd-rb {
}

.tabs-content-body .needprojectdetail .needpd-r .needpd-rb .needpd-rb-l {
	width: 100%;
}

.tabs-content-body .needprojectdetail .needpd-r .needpd-rb .needpd-rb-r {
	float: left;
	width: 100%;
}

.tabs-content-body .needprojectdetail .needpd-r .needpd-rb .completed a {
	color: #18bc9c;
}

.projectDetails.p-details {
	padding-top: 15px;
}

.tabs-content-body .needprojectdetail .needpd-r .needpd-rb .signin-btn {
	float: right;
	top: -12px;
	position: relative;
	min-width: 145px;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: -12px;
}

.tabs-content-body .needprojectdetail .needpd-r-r {
	float: right;
	padding-left: 5px;
	width: 100px;
	text-align: right;
	margin-top: 0;
}

.tabs-content-body .needprojectdetail .needpd-r-r p.heading {
	margin-bottom: 3px;
}

.tabs-content-body .needprojectdetail .needpd-r-r p.heading strong {
}

.tabs-content-body .needprojectdetail .needpd-r-r .owener-img {
	margin-bottom: 0px;
}

.tabs-content-body .needprojectdetail .needpd-r-r .owener-img img {
	width: 54px;
}

.tabs-content-body .needprojectdetail .needpd-r-r p.owener-name {
}

.tabs-content-body .needprojectdetail .needpd-r-r p.owener-name a {
	color: inherit;
}

.karma-points-dd-h {
}

.karma-points-dd {
	border: 1px solid #a9aeb1;
	padding: 20px;
	margin-bottom: 20px;
}

.karma-points-dd .k-dd-title {
	margin-bottom: 20px;
}

.karma-points-dd .k-dd-title h3 {
	margin: 0;
	font-size: 17px;
	font-weight: 400;
	background-image: url(../img/dd-minus.png);
	background-repeat: no-repeat;
	background-position: right;
	height: 24px;
	line-height: 25px;
}

.karma-points-dd ul.k-points-oners {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.usr-img-container {
	width: 54px;
	height: 54px;
	overflow: hidden;
}

.karma-points-dd ul.k-points-oners .usr-img-container {
	float: left;
	margin-right: 20px;
}

.usr-img-container img {
	max-width: 100%;
}

.karma-points-dd ul.k-points-oners li {
	margin-bottom: 10px;
}

.karma-points-dd ul.k-points-oners li:last-child {
	margin-bottom: 0;
}

.karma-points-dd ul.k-points-oners li img {
}

.karma-points-dd ul.k-points-oners li .onr-n-pnts {
	float: left;
	width: 167px;
}

.karma-points-dd ul.k-points-oners li .onr-n-pnts p {
	margin-bottom: 2px;
	font-size: 14px;
	color: #666666;
}

.karma-points-dd ul.k-points-oners li .onr-n-pnts p a {
	color: inherit;
}

.account-page .karma-cart {
	background: #eef1f2;
	border-color: #eef1f2;
	padding: 20px;
}

.received-box-cont {
	border: 1px solid #cbcfd1;
	margin-bottom: 20px;
	margin-top: 20px;
}

.received-box-cont:first-child {
	margin-top: 0;
}

.corner-badge {
	width: 90px;
	height: 90px;
	position: absolute;
	background: url(../img/corner-sprite.png);
	background-repeat: no-repeat;
	z-index: 999;
}

.accepted .corner-badge {
}

.rejected .corner-badge {
}

.received-box-cont .images-contianer {
	float: left;
	width: 30%;
	height: 220px;
	overflow: hidden;
}

.received-box-cont .images-contianer img {
	width: 100%;
	height: auto;
}

.received-box-cont .right-cont {
	float: left;
	padding: 20px;
	width: 70%;
}

.received-box-cont .right-cont h3 {
	margin: 0 0 15px 0px;
	font-weight: 500;
	max-height: 52px;
	overflow: hidden;
}

.received-box-cont .right-cont h3 a {
	color: inherit;
}

.received-box-cont .right-cont .user-area-box {
	border-top: 1px solid #a2a8ac;
	border-bottom: 1px solid #a2a8ac;
	padding: 2px 0;
}

.table-striped>tbody>tr>td:nth-child(3n), .table-striped>thead>tr.success>th:nth-child(3n), .table-striped>tbody>tr>td:last-child, .table-striped>thead>tr.success>th:last-child {
	text-align: center;
}

.table-striped>tbody>tr>td:nth-child(3n) span {
	display: block;
	height: 32px;
	width: 32px;
	margin-top: -6px;
	margin-bottom: -6px;
	margin: -6px auto;
}

.table-striped>tbody>tr>td:nth-child(3n) span.equ {
	background: url(../img/profile-sprite.png);
	background-position: 0 0;
}

.table-striped>tbody>tr>td:nth-child(3n) span.volu {
	background: url(../img/profile-sprite.png);
	background-position: 0px -38px;
}

.table-striped>tbody>tr>td:nth-child(3n) span.dona {
	background: url(../img/profile-sprite.png);
	background-position: 0px -75px;
}

.table-striped>tbody>tr>td:nth-child(3n) span.Kpoint {
	background: url(../img/profile-sprite.png);
	background-position: 0px -109px;
}

.table-striped>tbody>tr>td:nth-child(3n) span.contri {
	background: url(../img/profile-sprite.png);
	background-position: 0px -145px;
}

.received-box-cont .right-cont .user-area-box .owener-img {
	width: 54px;
	height: 54px;
	float: left;
	margin-right: 10px;
}

.received-box-cont .right-cont .user-area-box .box-username {
	color: #2c3e50;
	font-size: 18px;
	font-weight: 300;
	margin: 13px 0;
	display: block;
	float: left;
}

.received-box-cont .right-cont .user-area-box .owener-img img {
	max-width: 100%;
	max-height: 100%;
}

.received-box-cont .right-cont .user-area-box .msgs-count {
	float: right;
	color: #666666;
	display: block;
	margin: 15px 0;
	text-decoration: underline;
	font-weight: bold;
}

.received-box-cont .right-cont .bottom-box {
	padding: 10px 0;
}

.received-box-cont .right-cont .bottom-box .time-area {
	float: left;
	width: 255px;
}

.received-box-cont .right-cont .bottom-box .time-area .box-username {
}

.received-box-cont .right-cont .bottom-box .time-area p {
	margin: 0;
	color: #666666;
	float: left;
	width: 100%;
}

.received-box-cont .right-cont .bottom-box .time-area p span {
	width: 105px;
	display: block;
	float: left;
}

.received-box-cont .right-cont .bottom-box .time-area p span.status-accept {
	color: #81bd4a;
	padding-left: 20px;
	background-image: url(../img/ok-green.png);
	background-repeat: no-repeat;
	background-position: 1px 1px;
}

.received-box-cont .right-cont .bottom-box .time-area p span.status-reject {
	color: #ed1c24;
	padding-left: 20px;
	background-image: url(../img/cross-red.png);
	background-position: 1px 1px;
	background-repeat: no-repeat;
}

.received-box-cont .right-cont .bottom-box .buttons-group-box {
	float: right;
}

.received-box-cont .right-cont .bottom-box .buttons-group-box a {
	display: block;
	float: left;
	color: #ffffff;
	background: #18bc9c;
	padding: 9px 28px 9px 38px;
}

.received-box-cont .right-cont .bottom-box .buttons-group-box a.btn-reject {
	background-image: url(../img/ok.png);
	background-repeat: no-repeat;
	background-position: 17px 10px;
	border: 1px solid #914437;
	background-color: #c45c4a;
}

.received-box-cont .right-cont .bottom-box .buttons-group-box a.btn-accept {
	border: 1px solid #00ac8f;
	background-image: url(../img/cross.png);
	background-repeat: no-repeat;
	background-position: 17px 10px;
}

.received-box-cont .right-cont .bottom-box .buttons-group-box a.btn-accepted {
	font-weight: bold;
	font-size: 18px;
	padding: 7px 20px;
	border: 1px solid #00ac8f;
}

.received-box-cont .right-cont .bottom-box .buttons-group-box a.btn-rejected {
	background: #c45c4a;
	font-weight: bold;
	font-size: 18px;
	border: 1px solid #914437;
	padding: 7px 20px;
}

.karma-cart hr {
	border-top: 1px solid #a2a8ac;
}

.completeNeed .modal-footer {
	padding-top: 0;
}

.wish-badge {
	background-position: 0 0;
}

.offer-badge {
	background-position: -1px -97px;
}

.need-badge {
	background-position: -1px -190px;
}

#post-wait {
	height: 30px;
	text-align: center;
	clear: both;
}

#post-wait img {
	width: 20px;
	height: 20px;
	display: inline;
	float: none;
	vertical-align: bottom;
}

.edit-comment-div {
	display: block;
	margin-top: -10px;
	margin-bottom: 10px;
}

.edit-comment-div input[type="text"] {
	float: left;
	display: block;
	height: 30px;
	line-height: 30px;
	width: 400px;
	border: 1px solid #00AC8F;
}

.edit-comment-div input[type="text"]:focus {
	outline: none;
}

.edit-comment-div button {
	float: left;
	border: 1px solid #00AC8F;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #18BC9C;
	color: #ffffff;
	font-weight: bold;
	font-size: 16px;
	display: block;
	height: 30px;
	line-height: 28px;
	padding: 0 10px;
	margin: 0;
}

.edit-comment-div:after {
	content: "";
	display: block;
	clear: both;
}

.box-004 .corner-badge + a {
	display: block;
	border: 1px solid #A9AEB1;
	border-bottom: 0;
}

.container {
	min-width: 290px !important;
}

.notificationspage h2.content-heading {
	margin-top: 30px;
	margin-bottom: 10px;
}

.notilist {
}

.notibox {
	background: #e9ebed;
	margin-bottom: 2px;
	padding-left: 10px;
	padding: 7px 12px 7px 12px;
}

.notibox a {
	text-decoration: underline;
	color: #fb3197;
}

.notibox b, .notibox b a {
	color: #2c3e50;
}

.notibox span {
	color: #666666
}

.contactpage {
	background: #eef1f2;
	margin-top: 58px;
	margin-bottom: 58px;
	padding: 20px;
	overflow: hidden;
}

.contactpage h2.content-heading {
	margin-top: 5px;
}

.contactleft {
	width: 30%;
	float: left;
}

.contactright {
	width: 70%;
	float: left;
}

.contactleft .call {
	background: url(../img/contact.png) no-repeat 0px 0px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	margin-top: 30px;
	padding-left: 50px;
}

.contactleft .mail {
	background: url(../img/contact.png) no-repeat 0px -40px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	margin-top: 30px;
	padding-left: 50px;
}

.contactleft .web {
	background: url(../img/contact.png) no-repeat 0px -80px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	margin-top: 30px;
	padding-left: 50px;
}

.contactleft .web a, .contactleft .mail a {
	color: #2c3e50;
}

.contact-twitter {
	background: url(../img/contact-social.png) no-repeat 0px 0px;
	width: 32px;
	height: 30px;
	display: block;
	float: left;
	margin-left: 22px;
}

.contact-googleplus {
	background: url(../img/contact-social.png) no-repeat 0px -30px;
	width: 32px;
	height: 30px;
	display: block;
	float: left;
	margin-left: 22px;
}

.contact-facebook {
	background: url(../img/contact-social.png) no-repeat 0px -60px;
	width: 32px;
	height: 30px;
	display: block;
	float: left;
	margin-left: 22px;
}

.contactright .contactrr input {
	display: block;
	float: left;
	margin-right: 2%;
	width: 48%;
	height: 42px;
	margin-bottom: 30px;
	padding: 10px;
	border: 1px solid #d8dee1;
}

.contactright .contactrr {
	margin-top: 20px;
}

.contactright textarea {
	height: 150px;
	width: 98%;
}

.newspage {
	background: #eef1f2;
	margin-top: 60px;
	margin-bottom: 20px;
}

.newsbox {
	padding-bottom: 20px;
	border-bottom: 1px solid #a2a8ac;
	margin-top: 0;
	padding-bottom: 40px;
	padding-top: 0px;
}

.newsbox:after {
	content: "";
	clear: both;
	display: block;
}

.newsbox:last-child {
	border-bottom: 0;
}

.newsbox .newsbx-left {
	width: 35%;
	max-height: 220px;
	overflow: hidden;
	float: left;
}

.newsbox .newsbx-left img {
	width: 100%;
	height: auto;
}

.newsbox .newsbx-right {
	/*max-height: 220px;*/
	width: 65%;
	/*border: 1px solid #cbcfd1;*/
	float: left;
	padding: 20px;
	border-left: 0;
}

.newsbox .newsbx-right h3 {
	margin-top: 0px;
	font-weight: bold;
	color: #2c3e50;
}

.newsbox .newsbx-right h3 a {
	color: #2c3e50;
}

.newsbox .newsbx-right div.news-info {
	color: #a2a8ac;
}

.newsbox .newsbx-right p {
	color: #787B7E;
}

.newsbox .newsbx-right hr {
	margin-top: 15px;
	margin-bottom: 15px;
	border-top: 1px solid #a2a8ac;
}

.newsbox .newsbx-right p a {
	font-weight: bold;
	color: #666666;
	text-decoration: underline;
}

.nomargin {
	padding: 0;
	margin-bottom: 60px;
}

.load-more-full {
	display: block;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	background: #666666;
	color: #ffffff;
	text-decoration: none;
	padding: 6px;
	height: 36px;
	width: 100%;
	float: left;
}

.load-more-full:hover {
	opacity: .9;
	color: #ffffff;
	text-decoration: none;
}

a.load-more-full:focus {
	color: #ffffff;
	outline: none;
	text-decoration: none;
}

a.backlink {
	color: #2c3e50;
}

a.backlink .back {
	background: url(../img/profile-sprite.png) no-repeat -44px -454px;
	width: 20px;
	height: 20px;
	display: inline-block;
}

.newsdetail {
	padding: 20px 10px;
	text-align: justify;
}

.newsdetail h3 {
	font-weight: bold;
	color: #2c3e50;
}

.newsdetail img {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

.newsdetail p {
	margin-bottom: 20px
}

.newsdetail div.news-info {
	color: #a2a8ac;
}

.newsdetail hr {
	margin-top: 15px;
	margin-bottom: 15px;
	border-top: 1px solid #a2a8ac;
}

.mb60 {
	margin-bottom: 60px;
}

.faqspage {
	margin-top: 60px;
	margin-bottom: 60px;
	background: #eef1f2;
	padding: 30px;
}

input.searchfaq {
	width: 100%;
	height: 42px;
	display: block;
	float: left;
	padding: 10px;
	border: 1px solid #d8dee1;
}

.faqspage .accordion {
	padding-top: 20px;
	clear: both;
	text-align: justify;
}

.faqspage .accordion dt, .faqspage .accordion dd {
	padding: 15px;
}

.faqspage .accordion dt {
	border-bottom: 1px solid #dadfe1;
}

.faqspage .accordion dt a {
	display: block;
	color: #2c3e50;
	font-weight: bold;
	font-size: 16px;
}

.faqspage .accordion a span.faqarrow {
	display: inline-block;
	width: 21px;
	height: 21px;
	vertical-align: bottom;
	background: url(../img/faqsprite.png) no-repeat;
	margin-right: 15px;
	font-size: 16px;
}

.faqspage .accordion dt.yes a span.faqarrow {
	background-position: 0px -21px;
}

.faqspage .accordion dd {
	border-top: 0;
	font-size: 14px;
	color: #2c3e50;
}

.single-user-comment-title {
	clear: both;
	margin-bottom: 20px;
}

.single-user-comment-title:after {
	content: "";
	clear: both;
	display: block;
}

.single-user-comment-title > img {
	float: left;
	width: 56px;
	height: 56px;
}

.single-user-comment-title .suct-name {
	float: left;
	line-height: 56px;
	margin-left: 12px;
	font-size: 18px;
}

.single-user-comment-title .suct-back {
	float: right;
	margin-right: 130px;
	line-height: 56px;
}

.single-user-comment {
	clear: both;
}

.single-user-comment > img {
	display: none;
}

/*/// the css below is from dev/custom.css */
.popup-forgot-passowrd {
	margin-bottom: 0px;
	margin-top: 0px;
	min-height: 0px;
}

.cursor-pointer {
	cursor: pointer;
}

.vouch-form {
	margin-bottom: 0px;
	margin-top: 0px;
	min-height: 200px;
}

.project-image {
	width: 360px;
	height: 191px;
}

.wall-post-image {
	max-width: 250px !important
}

.user-comm-content img.landscape {
	max-width: 100%;
}

.reduce-bottom-margin .form-section-right, .reduce-bottom-margin .error {
	margin-bottom: 0px
}

.minimum-height {
	min-height: 450px;
}

.add-sec-bottom-fix .add-sec-bottom {
	margin-top: 0px;
}

.send-message-form {
	margin-bottom: 0px;
	margin-top: 0px;
}

.send-message-form .media-list {
	margin-top: 10px;
}

.send-message-form .media-list .media-list-box {
	display: inline-block;
	margin-right: 10px;
	position: relative;
}

.send-message-form .media-list .media-list-box a {
	font-size: 11px;
	line-height: 13px;
	width: 14px;
	height: 14px;
	text-align: center;
	background: #00cc99;
	color: #fff;
	border-radius: 100%;
	display: inline-block;
	margin-left: 3px;
}

.send-message-form a#add_attachments {
	display: block;
	background: url(../../img/profile-sprite.png);
	width: 30px;
	height: 30px;
	float: right;
	position: relative;
	right: 30px;
	background-position: 0px -206px;
}

.deactivate-account-form {
	margin-bottom: 0px;
	margin-top: 0px;
	min-height: 257px;
}

.deactivate-account-form .deactivate-text {
	margin-bottom: 15px;
}

.reight-side-image {
	width: 100%;
	height: auto;
}

.reight-side-image.landscape {
	height: auto;
	width: 100%;
}

.view-all {
	float: right;
	font-size: 15px;
}

.not-found {
	text-align: center;
}

.project-detail-banner {
	width: 100%;
	height: auto;
	z-index: 5;
}

.contentHolder {
	position: relative;
	margin: 0px auto 30px auto;
	padding: 0px;
	/*width: 1168px;*/
	height: auto;
	overflow: hidden;
}

.need-image {
	width: 287px;
	height: 167px;
}

.need-location {
	margin-left: 0px!important;
}

.offer-detail-banner {
	height: auto;
	width: 100%;
}

.send-project-message-form {
	min-height: 180px;
}

img.need-detail-img {
	height: auto;
	width: 100%;
	min-width: 100%;
}

img.need-detail-img.landscape {
	height: auto ;
	width:100%;
}

.box-2-disc a {
	text-transform: capitalize;
}

.user-comm-content a img {
	float: none;
}

.slct-single-img {
	padding-top: 10px;
}

.big-gallery-detail-image {
	width: 100%;
}

.small-gallery-detail-image {
}

#load-more-shop, .shop-items-container, #load-more-notifications {
	margin-bottom: 30px;
	margin-top: 30px;
}

.no-data-found .not-found, .recommend-no-data-found .not-found {
	border-bottom: 1px solid #d8dee1;
	border-top: 1px solid #d8dee1;
	color: #d8dee1;
	font-size: 24px;
	line-height: 50px;
	margin-top: 50px;
	text-align: center;
}

.karma-shop.not-found {
	margin-top: 140px;
}

.karma-shop-img {
	width: 280px!important;
}

.offerD-left .applynowbtn {
	background: none repeat scroll 0 0 #18bc9c;
	border: 1px solid #00ac8f;
	color: #fff;
	display: block;
	float: right;
	font-size: 18px;
	font-weight: bold;
	height: 40px;
	line-height: 38px;
	margin-top: 30px;
	text-align: center;
	width: 144px;
}

a.applynowbtn.applied {
	text-decoration: none;
	background: #ff0080;
	border: 1px solid #ff0080;
}

.points-note {
	margin-left: 0px;
	width: auto;
}

.need-on-popup {
	padding-bottom: 10px;
}

.offer-popup-message {
	margin-bottom: 10px!important;
}

.popup-dropdown {
	float: none!important;
}

.project-counter {
	color: #ffffff;
}

#send-invitation-modal .invite-friend {
	width: 48%;
	float: left;
	clear: none;
	margin-left: 8px
}

#send-invitation-modal .invite-friend label {
	margin-left: 2px;
}

#send-invitation-modal .form-section-right {
	width: 245px;
}

.offerD-left .applynowbtn {
	width: 147px;
}

#need-apply-now-modal .modal-text {
	margin-bottom: 30px;
}

#need-apply-now-modal .req-form {
	height: 165px;
	min-height: 165px;
}

.karma-points-dd .k-dd-title h3.plus {
	background-image: url("../../img/dd-plus.png");
}

.likes-count-popup {
	cursor: pointer;
	min-width: 0 !important;
}

.pointer {
	cursor: pointer;
}

.search-bottom {
	margin-bottom: 10px;
}

.participaid-adavnce-search {
	color: white;
}

.notificationspage a.load-more {
	display: block;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	background: #666666;
	color: #ffffff;
	text-decoration: none;
	padding: 6px;
	height: 36px;
	width: 100%;
	float: left;
	margin-top: 30px;
}

.shop-karma-left-content a.load-more.full-width {
	width: 100%;
}

.dashboard-post-image {
	max-width: 100%;
	height: auto;
}

.dashboard-post-image {
	max-width: 250px !important;
	height: 215px;
}

.dashboard-item-image {
	width: 298px;
	height: 190px;
}

.say-area-dashboard {
	width: 100%;
	border: none;
}

.dashboard-post {
	border: none;
}

.home-page-projects {
	margin-bottom: 20px;
}

.home-page-projects > a {
	height: 167px;
	overflow: hidden;
	width: 100%;
	display: block;
}

.privacy-setting-color-div {
	color: grey;
}

.privacy-setting-size-div {
	font-size: 18px;
}

.coming-soon-class {
	font-size: 11px;
}

.completed-btn {
	background: none repeat scroll #ff0080 !important;
	border: 1px solid #ff0080 !important;
	display: inherit !important;
	color: #ffffff !important;
	text-decoration: none !important;
}

.wrodWrapOff {
	word-wrap: normal !important;
}

.pink-cls:hover {
	color: #ff0080 !important;
	text-decoration: none !important;
}

.two-line-text {
	height: .5em;
	/*line-height: .5em;*/
	overflow: hidden;
}

.single-profile-img {
	height: 56px;
	width: 56px;
	float: left;
}

.single-post-comment {
	margin-left: 0px;
}

.blck-cls {
	color: #000000;
}

.max-height-msg--div {
	max-height: 448px;
	overflow: auto;
	width: 100%;
}

.need-area-max-height {
	margin-bottom: 10px;
}

.pink-cls-back:hover {/*background-color:#ff0080 */
!important;
	color: #ffffff !important;
	text-decoration: none !important;
}

.nav-width-according-scroll {
	width: 100%;
	overflow: hidden;
	margin-bottom: 20px;
}

.multiselect-container {
	height: 300px;
	overflow-y: scroll;
	width: 100%;
	overflow-x: hidden;
}

.carousel-indicators {
	width: auto;
	float: right;
	left: auto;
	right: 30px;
}

.carousel-indicators li {
	display: block;
	width: 70px;
	height: 40px;
	border-radius: 0;
	float: left;
	background-color: none;
	background-size: cover;
	border: 2px solid #fff;
	margin: 4px;
}

.carousel-indicators li.active {
	display: block;
	width: 70px;
	height: 40px;
	border-radius: 0;
	border: 2px solid #ff0080;
	margin: 4px;
}

.static-pages img {
	height: auto;
}

.alphas-sel {
	color: black;
	float: left;
	margin-right: 10px;
	margin-top: 8px;
	width: 140px;
	background-position: 107px -77px;
	padding-right: 3px;
}

.country-sel {
	background-position: 123px -77px !important;
}

.adv-search-dropdown-font-color {
	color: white !important;
	width: 100% !important;
	box-sizing: border-box !important;
}

.fix-with-dropdown {
	/* padding-left: 0px !important;*/
	padding-right: 0px !important;
	width: 100% !important;
}

.disabled-all a {
	pointer-events: none;
}

.error-post {
	color: red;
}

.float-none {
	float: none !important;
}

.wish-offer-need-img-btns {
	width: 270px !important;
	margin-left: 136px !important;
	margin-top: 14px !important;
}

#loading-image {
	display: none;
}

.answermyq {
	margin-left: -56px;
}

.ks-landing {
	font-size: 18px;
	margin-top: 34px;
}

.ks-nag-top {
	margin-top: 60px;
}

.org-alpha {
	width: 111px;
	background-position: 74px -77px;
}

.notificationspage .no-data {
	margin-top: 30px;
	margin-bottom: 10px;
}

/*.multiselect-inside .multiselect.dropdown-toggle.btn
{
.    overflow-x: hidden;
}*/
.form-section .selectinside .fix-with-dropdown {
	padding-right: 45px !important;
}

button {
	/*    overflow: hidden;*/
}

#security_question .myquestion.form-section-right {
	width: 700px !important;
}

.profile-picture-aspect-ratio {
	display: block;
	max-width: 262px !important;
	max-height: 140px !important;
	width: auto !important;
	height: auto !important;
}

.reduce-bottom-margin .note-description {
	margin-left: 30%;
}

.interstselectlist .parent-box {
	clear: both;
	width: 100%;
}

p.info-small {
	font-size: 11px;
}

.compt-small {
	font-size: 14px !important;
}

.shop-karma-left-content .projectsDetails {
	font-size: 18px;
	padding: 0 15px 25px;
}

.person-info .p-pic {
	height: 150px;
	width: 120px;
	float: right;
	margin-right: 25px;
}

.person-info + .person-info {
	margin-top: 50px;
}

.need-vldt-form p.info {
	/*width: 450px;*/
}

.author-info p, .author-info .user-avator {
	float: left;
}

.author-info .user-avator {
	height: 60px;
	width: 60px;
	margin-right: 10px;
}

.author-info .clr {
	clear: both;
}

.user-menu-avator {
	width: 30px;
	margin-left: 10px;
	height: 30px;
}

.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info img {
	height: auto;
	width: 60px;
}

ul.nav-right li.user-name-drowpd a img {
	width: 30px;
	height: auto;
}

.user-profile-avator {
	width: 160px;
	height: 160px;
	margin-right: 35px;
	float: left;
	display: block;
}

.user-credentials .prof-user img {
	width: 100%;
	height: auto;
	position: relative;
}

.say-some .user-say-some-avator, .say-some .view-project-avator, .view-projectjs-avator {
	width: 10%;
	float: left;
	padding-right: 14px;
}

.say-some img, .user-comment>img, .user-comment .view-projectjs-avator img {
	width: 100%;
	height: auto;
}

.a-commented .user-comment-avator {
	width: 54px;
	height: 54px;
	float: left;
	margin-right: 9px;
	overflow: hidden;
	display: block;
}

.comment-from img {
	width: 54px;
	float: left;
	margin-right: 10px;
	border: 1px solid #ccc;
	padding: 1px;
	position: relative;
}

.user-comment a img {
	/*float: left;*/
	/*  height: 56px;*/
	/*margin-right: 14px;
	width: 56px;*/
}

.morecontent span {
	display: none;
}

.morelink:focus {
	outline: none;
	text-decoration: none;
}

.morelink {
	display: block;
	color: #18bb9c;
}

.morelink:hover {
	text-decoration: underline !important;
}

.morelink:hover, .morelink:focus {
	color: #18bb9c !important;
}

.search-post-image a {
	display: block;
}

.search-single-post .search-post-image img {
	height: auto;
	width: 100%;
}

.mBoday-from a {
	height: 54px;
	width: 54px;
}

.brandname .color-blue {
	color: #2386c8;
}

.shop-karma-left-content .box-004 a.imgach {
	height: 160px !important;
	width: 100% !important;
	display: block;
}

.shop-karma-left-content .box-004 a img {
	height: auto !important;
	width: 263px !important;
	/*position: relative;*/
	top: 50%;
	/*/*transform: translateY(-50%)-webkit-filter: blur(1px) grayscale(100%);
	filter: blur(1px) grayscale(100%);*/
	min-height: 167px;
}

.shop-karma-left-content .box-004:hover a img {
	-webkit-filter: blur(0px) grayscale(0%); /* Safari 6.0 - 9.0 */
	filter: blur(0px) grayscale(0%);
}

.note-readmore {
	padding-top: 10px;
}

/* edit by shaheer 02-19-2017 */
.searchArea .AdSearch-holder {
}

.AdSearch.searchArea .AdSearch-holder li:first-child {
	width: 80%;
	margin: 0;
}

.AdSearch.searchArea .AdSearch-holder li:nth-child(2) {
	width: 35%;
	margin: 0;
}

.AdSearch.searchArea li:first-child {
	width: 20%;
}

.AdSearch .searchArea li:nth-child(2) {
	width: 45%;
}

.shop-search-top .input-group ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: table;
}

.shop-search-top .input-group ul li {
	float: left;
	display: table-cell;
}

.shop-search-top a.adv-search {
	background-color: #18BC9C; /*url(../img/main-sprite.png) no-repeat -74px 4px*/
;
	border: 1px solid #18BC9C;
	padding: 8px 8px 8px;
	width: 44px;
	margin: 0 0 0 2px;
	color: #ECF0F1;
	font-size: 24px;
	line-height: 27px;
}

.shop-search-top a.adv-search:hover {
	border: 1px solid #ff0080 !important;
}

.AdSearch.searchArea .AdSearch-holder li:nth-child(2) a {
	background-color: #18BC9C;/*url(../img/main-sprite.png) no-repeat -74px 4px;*/
	float: left;
	border: 1px solid #18BC9C;
	padding: 11px 7px 10px;
	/*text-indent: -99999px;*/
	width: 42px !important;
	margin: 0 0 0 2px;
}

.shop-search-top a.adv-search a span, .AdSearch.searchArea .AdSearch-holder li:nth-child(2) a span {
	font-size: 25px;
	font-weight: normal;
	color: #ECF0F1
}

.shop-search-top a.adv-search a:hover, .AdSearch.searchArea .AdSearch-holder a:hover {
	text-decoration: none !important;
	border: 1px solid #ff0080 !important;
}

.karma-shop-nav ul.shop-nav li a:hover, .karma-shop-nav ul.shop-nav li a:hover .glyphicon, .shop-search-top a.adv-search .glyphicon:hover, .shop-search-top a.adv-search a .glyphicon:hover, .AdSearch.searchArea .AdSearch-holder li:nth-child(2) a .glyphicon:hover {
	text-decoration: none;
	color: #ECF0F1;
}

/* Sweep To Right */
.karma-shop-nav ul.shop-nav li a, .hvr-sweep-to-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}

.shop-search-top a.adv-search:before, .AdSearch.searchArea .AdSearch-holder li:nth-child(2) a:before, .karma-shop-nav ul.shop-nav li a:before, .hvr-sweep-to-right:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ff0080;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.shop-search-top a.adv-search:before, .AdSearch.searchArea .AdSearch-holder li:nth-child(2) a:before {
	background-color: #ff0080; /*url(../img/main-sprite.png) no-repeat -74px 4px;*/
}

.karma-shop-nav ul.shop-nav li a:hover, .hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
	color: white;
}

.shop-search-top a.adv-search:hover:before, .AdSearch.searchArea .AdSearch-holder li:nth-child(2) a:hover:before, .karma-shop-nav ul.shop-nav li a:hover:before, .hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}

/* Sweep To Left */
.hvr-sweep-to-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	background: #18bc9c;
}

.hvr-sweep-to-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ff0080;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-sweep-to-left:hover, .hvr-sweep-to-left:focus, .hvr-sweep-to-left:active {
	color: white;
}

.hvr-sweep-to-left:hover:before, .hvr-sweep-to-left:focus:before, .hvr-sweep-to-left:active:before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}

.hvr-sweep-to-right .signin-btn {
	background-color: transparent;
}

.singin.hvr-sweep-to-right {
	background: #18BC9C;
}

.founder-area {
	overflow: hidden;
	text-align: justify;
}

.founder-area .img-holder {
	overflow: hidden;
	padding: 20px;
}

.founder-area p {
	overflow: hidden;
	text-align: justify;
}

.comment-container {
	float: left;
	width: 90%;
}

.profile-img-holder {
	float: left;
	width: 10%;
	padding-right: 10px;
}

.profile-img-holder img {
	width: 100%;
	height: auto;
}

.karmabalanceDetails {
	overflow: hidden;
	padding: 20px 5px;
}

.ms-choice {
	background-position: 120% -81px;
	width: 100% !important;
}

.ms-parent {
	width: 100% !important;
}

.needproject_img_holder {
	float: left;
}

.needproject_img_holder img {
	width: 100%;
	height: auto;
}

/* @media screen */
@media only screen and (max-width: 1199px) {

	.navbar .navbar-collapse {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	/*.subcription input[type="text"] {
            width: 290px;
        }*/

	.karma-shop-head-low .extended-low select {
		background-position: 145% -110px;
	}

	select {
		background-position: 126% -77px;
	}

	.box-004-lower .need-box-bottom p {
		width: 100%;
		margin: 0 0 10px 0;
	}

	.searchArea li:nth-child(2) {
		width: 45%;
	}

	.shop-search-mid {
		width: 70%;
		float: left;
	}

	.shop-search-top input[type="button"] {
		width: 180px;
	}

	.loggedin {
		margin-top: -50px;
		margin-bottom: -13px;
	}

	.user-comm-content {
		width: 100%;
	}

	.comm-lower-btns,  .a-commented {
		margin-left: 0;
		width: 100%;
	}

	.mail-list-left .mail-nav a {
		width: 78px;
	}

	.ms-choice {
		background-position: 127% -81px;
		width: 100% !important;
	}

	.add-sec-bottom select {
		background-position: 90px -77px;
	}

	.interstselectlist .child-box .chkbxtitle {
		width: 160px;
	}

	.country-sel {
		background-position: 93px -77px !important;
	}

	.shop-search-top input[type="button"] {
		width: 179px;
	}

	.tableType .tableTypeRow div,  .tableType .tableTypeRow .box-left {
		border: none;
		width: 100%;
	}
}

@media only screen and (max-width: 991px) {

	.slider-cap h3 {
		font-size: 28px;
		line-height: 30px;
		margin: 0;
	}

	.slider-cap h3 span {
		line-height:47px;
	}

	.searchArea ul li {
		margin-bottom: 5px;
		text-align: left;
		float: left;
	}

	.searchArea li:first-child {
		width: 100%;
	}

	.searchArea li:nth-child(2) {
		width: 73%;
		margin: 0;
	}

	.searchArea li:nth-child(3) {
		width: 25%;
	}

	.searchArea ul ul {
		padding: 0;
	}

	.searchArea .AdSearch-holder li:first-child {
		width: 60%;
		margin: 0;
	}

	.searchArea .AdSearch-holder li input[type="button"] {
		font-size: 20px;
		height: 49px;
	}

	.searchArea .AdSearch-holder li:nth-child(2) {
		width: 40%;
		margin: 0;
	}

	.AdSearch.searchArea li:first-child {
		width: 100%;
	}

	.AdSearch.searchArea li:nth-child(2) {
		width: 74%;
	}

	.AdSearch.searchArea .AdSearch-holder li:nth-child(2) {
		width: 50px;
		margin: 0;
	}

	.AdSearch.searchArea li:nth-child(3) {
		margin-right: 0;
	}

	.searchArea .AdSearch-holder li .adv-search {
		padding: 13px 5px;
		display: inherit;
	}

	.karma-shop-head-low .extended-low select {
		background-position: 180% -110px;
	}

	.form-section .form-section-right {
		width: 70%;
	}

	.signup-form .form-section .form-section-right {
		padding-right: 10px;
	}

	.login-fb, .signup-fb {
		margin-left: 0;
		width: 100%;
	}

	.shop-search-mid {
		width: 50%;
	}

	.shop-search-top input[type="button"] {
		font-size: 20px;
		width: 123px;
		padding: 12px 0;
		height: 49px;
	}

	.shop-search-top .input-group {
		width: 30%;
	}

	.shop-search-top .input-group.shop-search-mid {
		width: 70%
	}

	.search-single-post h3 {
		font-size: 18px;
		line-height: 28px;
		margin: 0 0 8px;
	}

	.box-004 > a {
		max-height: inherit;
		height: auto;
	}

	.box-type-2 > a {
		height: auto;
	}

	.pDetail-banner .pDetail-banner-img {
		height: auto;
	}

	.pDetail-banner .pDetail-banner-img .banner-heading {
		position: static;
		padding-top: 26px;
		padding-left: 28px;
		height: auto;
		overflow: hidden;
	}

	.pDetail-banner .pDetail-banner-img .banner-heading h1,  .pDetail-banner .pDetail-banner-img .banner-heading p {
		width: 100%;
		padding-right: 16px;
	}

	.shop-search-top {
		padding: 25px 0 25px 10px;
	}

	.AdSearch.searchArea .AdSearch-holder li:first-child {
		width: 73%;
		margin: 0;
	}

	.loggedin {
		margin-top: -40px;
		margin-bottom: 0;
	}

	.user-info .about-user p {
		width: 93%;
	}

	.user-credentials {
		height: auto !important;
	}

	.user-awards {
		background: #F6F8F8;
		padding-bottom: 10px;
		height: 300px;
		height: auto !important;
		padding: 20px;
		border: 1px solid #D4D6D8;
	}

	ul.user-tabs li a {
		padding: 13px 23px;
	}

	.user-popularity {
		float: none;
	}

	.loggedin {
		margin-bottom: 0;
	}

	.home-page-projects > a {
		height: auto;
	}

	.offer-D-right .offer-right-col .karma-right > a {
		height: auto;
	}

	.login-form {
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.signup-form select {
		width: 100%;
		background-position: 121% -77px;
	}

	.add-sec-bottom select {
		background-position: 105px -77px;
	}

	.ms-choice {
		width: 344px;
		background-position: 122% -81px;
	}

	.signup-form {
		margin-bottom: 20px;
		margin-top: 20px;
	}

	h2.content-heading {
		margin-top: 20px;
	}

	.country-sel {
		background-position: 110px -77px !important;
	}

	.karma-points-dd-h {
		padding: 0;
	}
	/*
        .box-type-3 img {
            height: auto;
            max-height: inherit;
        }
        */
}

@media only screen and (max-width: 768px) {

	.navbar .navbar-collapse .nav-left {
		min-width: 300px;
	}

	.navbar .navbar-collapse {
		width: 200px;
		/*border-radius:0 0 0 100%;*/
		position: absolute;
		background: rgba(238,241,242,1);
		margin: 0;
		right: 0;
		transition-property: all;
		transition-duration: 1s;
		transition-timing-function: ease;
		top: 85px;
	}

	.navbar .navbar-collapse.in {
		width: 100%;
		background: rgba(238,241,242,1);
		border-radius: 0px;
		z-index: 999;
	}

	.navbar-toggle {
		margin-top: 28px;
	}

	.navbar .navbar-collapse .nav-left > li {
		margin: 0;
	}

	.navbar-default .navbar-nav > li > a {
		padding: 10px 5px 10px;
		border-bottom: 1px solid #ccc;
	}

	.navbar-default .navbar-nav > li > a:hover {
		background: #2386c8;
		color: #fff;
	}

	.navbar .navbar-collapse .nav-right li {
		margin-left: 0;
	}

	.navbar .navbar-collapse .nav-right li a {
		border: none;
	}

	.navbar-default .navbar-nav > li > a.header-search-button:hover, .navbar .navbar-collapse .nav-right li a:hover {
		background-color: Transparent;
		color: #2C3E50;
	}

	.navbar-default .navbar-nav > li > a.header-search-button {
		margin: 0 auto;
		color: #2C3E50;
	}

	.carousel-caption {
		position: static;
	}

	.slider-cap {
		width: 100%;
		padding: 10px 5px 20px;
	}

	.carousel-indicators {
		bottom: auto;
		top: 45%;
		display: none;
	}

	.carousel-inner {
		background-color: #fff;
	}

	.slider-cap h3 span {
		background-color: transparent;
		color: #2C3E50;
		line-height: 33px;
	}

	.slider-cap h3 {
		font-size: 24px;
		line-height: 27px;
	}

	.searchArea ul li input[type="button"] {
		font-size: 15px;
	}

	.searchArea li:nth-child(2) {
		width: 75%;
		margin: 0;
	}

	.searchArea li:nth-child(3) {
		width: 25%;
		margin: 0;
	}

	.ks-landing {
		padding: 0 12px;
		text-align: justify;
	}

	.AdSearch.searchArea li:nth-child(2) {
		width: 54%;
	}

	.AdSearch.searchArea li:nth-child(3) {
		width: 45%;
	}

	.karma-shop-head-low .extended-low {
		margin: 0 0 12px;
	}

	.karma-shop-head-low .extended-low select {
		background-position: 115% -110px;
	}

	.form-section .form-section-left,  .form-section .form-section-right {
		width: 100%;
	}

	.form-section .form-section-right {
		margin-bottom: 0;
	}

	.form-horizontal .control-label {
		line-height: 20px;
	}

	.form-section .form-section-right.leftmrl {
		margin-left: 0;
		width: 100% !important;
	}

	.karma-shop-head-right {
		width: 100%;
	}

	.shop-search-top {
		width: 100%;
	}

	.contactright, .contactleft {
		width: 100%;
	}

	.searchArea ul li input[type="text"] {
		font-size: 15px;
		line-height: 28px;
	}

	.karma-shop-nav {
		margin-top: 35px;
	}

	.newsbox .newsbx-left {
		float: none;
		max-height: inherit;
		overflow: hidden;
		width: 100%;
	}

	.newspage {
		margin-top: 20px;
	}

	.newsbox .newsbx-right {
		border-left: 0 none;
		float: none;
		padding: 20px 0;
		width: 100%;
	}

	.shop-search-top .input-group.shop-search-mid {
		width: 100%;
		margin-bottom: 12px;
	}

	.shop-search-top {
		padding: 15px 10px;
	}

	.AdSearch.searchArea li:nth-child(2) {
		width: 100%;
		margin-bottom: 12px;
	}

	.AdSearch.searchArea li:nth-child(3) {
		width: 100%;
	}

	.AdSearch.searchArea .AdSearch-holder li:first-child {
		width: 110px;
		margin: 0;
	}

	.AdSearch.searchArea .AdSearch-holder li:nth-child(2) a {
		padding: 11px 10px;
		width: 48px !important;
	}

	.shop-search-top a.adv-search {
		padding: 9px 11px;
		width: 48px;
	}

	.modal-dialog {
		width: 100%;
		margin: 10px 0;
	}

	.modal-dialog .modal-body .scrollable-area {
		width: 100%;
		height: 247px;
	}

	.testi-right {
		padding: 0 0 10px;
	}

	/*.subcription input[type="text"] {
            width: 80%;
        }*/
	.contactpage {
		margin-bottom: 10px;
		margin-top: 10px;
	}

	#ytplayer {
		height: 400px !important;
	}

	.loggedin {
		margin-top: -4px;
		margin-bottom: 0;
		float: right;
	}

	.dropdown-menu {
		right: 0;
		left: auto;
	}

	ul.user-tabs li {
		float: none;
	}

	.user-profile-avator {
		width: 25%;
		margin-right: 3%;
	}

	.user-credentials .user-info {
		width: 71%;
		padding-right: 15px;
		float: left;
	}

	.user-info .about-user p {
		width: 84%;
	}

	.aboutleft {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.comment-container {
		overflow: hidden;
	}

	.content-left {
		padding: 20px 10px;
	}

	.signup-form select {
		background-position: 114% -77px;
	}

	.reduce-bottom-margin .note-description {
		margin-left: 0;
	}

	.points-note {
		margin-left: 0px;
		width: 100%;
	}

	.btns-left.leftmrl {
		margin-left: 0;
	}

	.wish-offer-need-img-btns {
		width: 100% !important;
		margin-left: 0 !important;
		margin-top: 10px !important;
		float: left;
	}

	.ms-choice {
		background-position: 114% -81px;
	}

	.form-section .add-section {
		width: 100% !important;
		float: left;
	}

	.add-sec-bottom select {
		background-position: 175% -77px !important;
	}

	.interstselectlist {
		width: 100%;
	}

	.add-sec-bottom .form-section input[type="text"] {
		width: 100%;
	}

	.form-section .bottomline {
		clear: both;
		margin-left: 0;
	}

	/*.table-striped tr td:nth-child(2),
        .table-striped tr th:nth-child(2){
            display:none;
            width:0px;
        }*/
	.shop-karma-left-content .box-004 a.imgach {
		height: auto !important;
	}
	.foot-links ul {
		padding-left: 14px;
		margin-bottom: 20px;
	}
	p.footer-cont {
		margin-top: 32px;
		margin-bottom: 29px;
	}
	.footer-social ul li {
		/*float:left;*/
		margin: 10px;
	}

	.karma-points-dd-h{
		margin-top:10%;
		margin-left:10%;
	}
}

@media only screen and (max-width: 700px) {

	.shop-search-top .input-group.shop-search-mid {
		width: 100%;
		margin-bottom: 12px;
	}

	.shop-search-top .input-group {
		width: 100%;
	}

	.ms-choice,  .signup-form select {
		background-position: 117% -77px;
	}

	.add-sec-bottom select {
		background-position: 205% -77px !important;
	}

	.box-top-left,  .need-box-topl {
		float: none;
		width: 100%;
	}

	.karma-points-dd-h{
		margin-top:10%;
		margin-left:10%;
	}
}

@media only screen and (max-width: 650px) {

	.add-sec-bottom select {
		background-position: 232% -77px !important;
	}

	.noti-center {
		width: 30%;
	}

	.noti-email {
		width: 20%;
	}

	.karma-points-dd-h{
		margin-top:10%;
		margin-left:10%;
	}
}

@media only screen and (max-width: 600px) {

	.add-sec-bottom select {
		background-position: 232% -77px !important;
	}

	.add-sec-bottom select {
		background-position: 262% -77px !important;
	}
	.karma-points-dd-h{
		margin-top:10%;
		margin-left:10%;
	}
}

@media only screen and (max-width: 550px) {

	.signup-form select {
		background-position: 120% -77px;
	}

	.ms-choice {
		background-position: 120% -81px;
	}

	.tabs-content-body .needprojectdetail .needpd-l {
		width: 100%;
		padding-bottom: 20px;
	}

	.tabs-content-body .needprojectdetail .needpd-r {
		padding-left: 0;
		width: 100%;
	}

	.add-sec-bottom select {
		background-position: 110px -77px !important;
	}

	.offer-details .odbelow .offerD-left {
		float: left;
		width: 100%;
	}

	.offer-details .odbelow .offerD-right {
		float: right;
		margin-top: 20px;
		width: 100%;
		margin-bottom: 20px;
	}

	.offer-details .a-commented {
		margin-bottom: 10px;
	}

	.type-send-msg button {
		width: 75px;
		font-size: 15px;
	}

	.type-send-msg input[type="text"] {
		padding-right: 125px;
	}

	.profileprojects h2.content-heading a {
		float: none;
		display: block;
		text-align: right;
	}

	.popover-left .user-info img {
		height: auto;
		width: 30%;
		float: left;
		margin-right: 10px;
	}

	.popover-left .user-info h2 {
		margin: 0;
		font-size: 20px;
	}

	.login-form.settings,  .home-feeds .quick-links {
		display: none;
	}
	.karma-points-dd-h{
		margin-top:10%;
		margin-left:10%;
	}
}

@media only screen and (max-width: 500px) {

	.add-sec-bottom select {
		background-position: 83px -77px !important;
	}

	.interstselectlist .child-box .chkbxtitle {
		width: 135px;
	}

	button.btn-gray {
		border: 1px solid #dddddd;
		background: #dadfe1;
		border: 1px solid #dadfe1;
		height: 40px;
		padding: 6px 12px;
		font-size: 18px;
		font-weight: bold;
		margin-left: 0;
		min-width: 108px;
	}

	.comment-container {
		float: left;
		width: 100%;
	}

	.profile-img-holder {
		float: left;
		overflow: hidden;
		padding-right: 10px;
		width: 65px;
		padding-bottom: 10px
	}

	.pDetail-banner .pDetail-banner-img img {
		display: none;
	}

	.pDetail-banner .pDetail-banner-img div.pDetail-banner-details {
		position: static;
		width: 100%;
		background: rgb(218, 223, 225);
	}

	.pDetail-banner .pDetail-banner-img div.pDetail-banner-details .author-info {
		width: 100%;
		margin: 0;
	}

	.say-area {
		width: 100%;
	}

	.say-some .user-say-some-avator {
		width: 65px;
		margin-bottom: 10px;
	}

	.user-cont-right a.load-more {
		margin-left:7%;
	}

	.karma-points-dd-h{
		margin-top:20%;
		margin-left:1%;
	}
}

@media only screen and (max-width: 480px) {

	a.navbar-brand img {
		width: 225px;
	}

	.navbar-toggle {
		margin-top: 20px;
	}

	.searchArea li:nth-child(2) {
		width: 60%;
	}

	.searchArea li:nth-child(3) {
		width: 40%;
	}

	.subcription input[type="text"] {
		width: 70%;
	}

	.subcription input[type="button"] {
		width: 100%;
		margin: 0;
		padding: 8px 4px;
		font-size: 15px;
	}

	.foot-links ul, .foot-links p, .subcription, .footer-right ul {
		padding-left: 0px;
	}

	.footer-right ul {
		overflow: hidden;
		margin-bottom: 0;
		padding-left: 2%;
	}

	.footer-right ul li {
		float: left;
		width: 20%;
		height: 10%;
		margin-bottom: 2%;
	}

	h2.content-heading {
		font-size: 20px;
		margin-top: 20px;
	}

	/*.AdSearch.searchArea li:nth-child(2) {
            width: 68%;
        }

        .AdSearch.searchArea li:nth-child(3) {
            width: 73px;
        }*/

	.searchArea .AdSearch-holder li .adv-search {
		padding: 3px 4px;
	}

	.karma-shop-head-low .extended-low select {
		background-position: 134% -110px;
	}

	.box-type-3 img,  .karma-shop-img {
		width: 100% !important;

	}

	.karma-right {
		width: 100%;
		padding-left: 0;
	}

	/*.searchArea .AdSearch-holder li input[type="button"], .shop-search-top input[type="button"] {
            background: #18BC9C url(../img/cross.png) no-repeat 15px 13px;
            text-indent: -9999px;
            overflow: hidden;
            width: 50px;
        }

        .searchArea .AdSearch-holder li input[type="button"]:hover, .shop-search-top input[type="button"]:hover {
            background: #ff0080 url(../img/cross.png) no-repeat 15px 13px;
        }*/
	.searchArea ul li input[type="text"] {
		font-size: 13px;
	}

	.faqspage {
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 10px;
	}

	.pDetail-banner .pDetail-banner-img div.pDetail-banner-details {
		right: 0;
	}

	.project-detail-banner {
		height: 283px;
	}

	.pDetail-banner .pDetail-banner-img .banner-heading {
		padding: 10px;
	}

	.founder-area .img-holder {
		width: 100%;
	}

	.founder-area .img-holder img {
		margin: 0 auto;
	}

	.AdSearch.searchArea .AdSearch-holder li:first-child {
		width: 123px;
		margin: 0;
	}

	#ytplayer {
		height: 270px !important;
	}

	.karma-shop-nav h3 {
		font-size: 20px;
	}

	.received-box-cont .images-contianer,  .received-box-cont .right-cont {
		width: 100%;
	}

	.signup-form select {
		background-position: 128% -77px;
	}

	.ms-choice,  .signup-form select {
		background-position: 128% -77px;
	}

	.signin-btn {
		/*width:140px;*/
		font-size: 16px;
	}

	button.btn-gray {
		font-size: 16px;
		margin-top: 5px;
	}

	.add-sec-bottom select {
		background-position: 76px -77px !important;
	}

	.noti-lower-btns .btn-right {
		float: none;
		margin-top: 0;
		overflow: hidden;
		width: 100%;
		clear: both;
		padding-top: 5px;
	}

	#send-invitation-modal .invite-friend {
		width: 100%;
	}

	#send-invitation-modal .form-section-right {
		width: 100%;
	}

	.navbar .navbar-collapse {
		top: 65px;
	}

	.profileprojects h2.content-heading a {
		/* float: right; */
		font-size: 14px;
		margin-left: 0;
		display: block;
		text-align: right;
		color: #18bc9c;
		line-height: 30px;
	}

	.needpd-r,  .needpd-l {
		float: none;
		width: 100%;
	}

	.comment-container {
		float: left;
	}

	.profile-img-holder {
		float: left;
		padding-right: 10px;
	}


}

@media only screen and (max-width:400px) {

	.add-sec-bottom select {
		background-position: 57px -77px !important;
	}

	.interstselectlist .checkbox {
		width: 100%;
	}
	.testi-content .pro-img-holder{
		width:25%;
	}
	.testi-content .text-holder{
		width:69%;
	}
	.footer-right ul li {
		float: left;
		width: 20%;
		height: 10%;
	}
	.user-cont-right a.load-more {
		margin-left:7%;
	}

	.karma-points-dd-h{
		margin-top:14%;
		margin-left:1%;
	}
}

@media only screen and (max-width: 380px) {

	.searchArea li:nth-child(2) {
		width: 63%;
	}

	.searchArea li:nth-child(3) {
		width: 35%;
	}


	.karma-shop-head-low .extended-low select {
		background-position: 143% -110px;
	}

	.subcription input[type="button"] {
		width: 100%;
	}

	.subcription input[type="text"] {
		width: 65%;
	}

	.subcription .hvr-sweep-to-left {
		width: 35%;
	}

	/*.testi-content .pro-img-holder {
		float: left;
		width: 100%;
		border: 3px solid #00cc99;

		max-height: inherit;
		overflow: hidden;
		margin-bottom: 10px;
	}

	.testi-content .text-holder {
		width: 100%;
		padding: 0 0 12px 0;
	}*/

	.carousel-control {
		top: 50%;
	}

	#ytplayer {
		height: 200px !important;
	}

	.mail-list-left .mail-nav {
		padding: 17px;
	}

	.add-a-comment button {
		padding: 5px 10px;
	}

	.add-a-comment input[type="text"] {
		padding-right: 87px;
	}

	.profile-img-holder {
		float: left;
	}

	.comment-container {
		width: 100%;
	}

	.say-area {
		width: 100%;
	}

	.signup-form select {
		background-position: 135% -77px;
	}

	.login-form,  .signup-form {
		padding: 20px 10px;
	}

	.say-some .view-project-avator {
		width: 20%;
	}
	.footer-right ul li {
		float: left;
		width: 20%;
		height: 8%;
		/*margin-bottom: 2%;*/
	}
	.karma-points-dd-h{
		margin-top:20%;
	}
}

@media only screen and (max-width: 360px) {
	.footer-right ul li {
		float: left;
		width: 20%;
		height: 10%;
		/*margin-bottom: 2%;*/
	}
	.user-cont-right a.load-more {
		margin-left:7%;
	}

	.karma-points-dd-h{
		margin-top:20%;
		margin-left:1%;
	}

}

@media only screen and (max-width: 340px) {

	.karma-shop-head-low .extended-low select {
		background-position: 155% -110px;
	}

	#ytplayer {
		height: 174px !important;
	}

	.ms-choice,  .signup-form select {
		background-position: 144% -77px;
	}

	.need-box-bottom a.applynowbtn {
		margin: 0 0 10px;
	}

	.karma-points-dd-h{
		margin-top:10%;
		margin-left:10%;
	}
}

@media only screen and (max-width: 320px) {

	#ytplayer {
		height: 163px !important;
	}

	.ms-choice,  .signup-form select {
		background-position: 150% -77px;
	}
	.karma-points-dd-h{
		margin-top:10%;
		margin-left:10%;
	}
}
.dN{display: none}

.btn {
	padding: 3px 13px;
}

.simple-selected {

		color: #2386c8;
		font-size: 26px;
}
.utb-ifrm-css{
	height: 480px;
}

@media only screen and (max-width: 478px) {

	.utb-ifrm-css{
		height: auto;
	}

}

.sponsors_img{
	margin-top:10px;
}

.footer-right li a img {
	margin-top: 10px;
	height: 75px;
}

.item .image .video_button{
	height:10%;
	width:7%;
	margin-left: 45%;
	margin-top: 25%;
	cursor: pointer;
	position:absolute;
}

.item .image .video_poster{
	cursor:pointer;

}
.item .youtube-video{
	display: none;
}

.fa-facebook:hover {
	color: #acbdf9;
}
.fa-twitter:hover {
	color:#1DA1F2;
}

.fa-linkedin:hover {
	color:#4875B4;
}

.fa-instagram:hover {
	color: #E03A5D;
}
.fa-youtube:hover {
	color:#FF3333;
}
.crop-preview{
	float: left;
}